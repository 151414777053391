"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } /**
                                                                                                                                                                                                                                                                                                                                                                                           * eXtend Media Paywall Service API
                                                                                                                                                                                                                                                                                                                                                                                           * Media Wall API Specification
                                                                                                                                                                                                                                                                                                                                                                                           *
                                                                                                                                                                                                                                                                                                                                                                                           * The version of the OpenAPI document: v1
                                                                                                                                                                                                                                                                                                                                                                                           * Contact: t.ioannidis@extend.gr
                                                                                                                                                                                                                                                                                                                                                                                           *
                                                                                                                                                                                                                                                                                                                                                                                           * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
                                                                                                                                                                                                                                                                                                                                                                                           * https://openapi-generator.tech
                                                                                                                                                                                                                                                                                                                                                                                           * Do not edit the class manually.
                                                                                                                                                                                                                                                                                                                                                                                           *
                                                                                                                                                                                                                                                                                                                                                                                           */
/**
* Enum class ResultCode.
* @enum {}
* @readonly
*/
class ResultCode {
  constructor() {
    /**
     * value: "SUCCESS"
     * @const
     */
    _defineProperty(this, "SUCCESS", "SUCCESS");
    /**
     * value: "FAILURE"
     * @const
     */
    _defineProperty(this, "FAILURE", "FAILURE");
    /**
     * value: "GENERIC_FAILURE"
     * @const
     */
    _defineProperty(this, "GENERIC_FAILURE", "GENERIC_FAILURE");
    /**
     * value: "BAD_REQUEST"
     * @const
     */
    _defineProperty(this, "BAD_REQUEST", "BAD_REQUEST");
    /**
     * value: "NOT_FOUND"
     * @const
     */
    _defineProperty(this, "NOT_FOUND", "NOT_FOUND");
    /**
     * value: "UNAUTHORIZED"
     * @const
     */
    _defineProperty(this, "UNAUTHORIZED", "UNAUTHORIZED");
    /**
     * value: "NOT_ALLOWED"
     * @const
     */
    _defineProperty(this, "NOT_ALLOWED", "NOT_ALLOWED");
    /**
     * value: "INCORRECT_EMAIL_OR_PASSWORD"
     * @const
     */
    _defineProperty(this, "INCORRECT_EMAIL_OR_PASSWORD", "INCORRECT_EMAIL_OR_PASSWORD");
    /**
     * value: "CREATED"
     * @const
     */
    _defineProperty(this, "CREATED", "CREATED");
    /**
     * value: "TWO_STEP_REQUIRED"
     * @const
     */
    _defineProperty(this, "TWO_STEP_REQUIRED", "TWO_STEP_REQUIRED");
    /**
     * value: "MULTI_USER"
     * @const
     */
    _defineProperty(this, "MULTI_USER", "MULTI_USER");
    /**
     * value: "SUBSCRIPTION_EXPIRED"
     * @const
     */
    _defineProperty(this, "SUBSCRIPTION_EXPIRED", "SUBSCRIPTION_EXPIRED");
  }
  /**
  * Returns a <code>ResultCode</code> enum value from a Javascript object name.
  * @param {Object} data The plain JavaScript object containing the name of the enum value.
  * @return {module:model/ResultCode} The enum <code>ResultCode</code> value.
  */
  static constructFromObject(object) {
    return object;
  }
}
exports.default = ResultCode;