"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GetFullReportDetailsV2Result model module.
 * @module model/GetFullReportDetailsV2Result
 * @version 1.1.3-alpha.1
 */
class GetFullReportDetailsV2Result {
  /**
   * Constructs a new <code>GetFullReportDetailsV2Result</code>.
   * @alias module:model/GetFullReportDetailsV2Result
   */
  constructor() {
    GetFullReportDetailsV2Result.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>GetFullReportDetailsV2Result</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GetFullReportDetailsV2Result} obj Optional instance to populate.
   * @return {module:model/GetFullReportDetailsV2Result} The populated <code>GetFullReportDetailsV2Result</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GetFullReportDetailsV2Result();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = _ApiClient.default.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('country_id')) {
        obj['country_id'] = _ApiClient.default.convertToType(data['country_id'], 'String');
      }
      if (data.hasOwnProperty('available_from')) {
        obj['available_from'] = _ApiClient.default.convertToType(data['available_from'], 'String');
      }
      if (data.hasOwnProperty('distribution_plan_id')) {
        obj['distribution_plan_id'] = _ApiClient.default.convertToType(data['distribution_plan_id'], 'String');
      }
      if (data.hasOwnProperty('season_number')) {
        obj['season_number'] = _ApiClient.default.convertToType(data['season_number'], 'Number');
      }
      if (data.hasOwnProperty('distribution_plan')) {
        obj['distribution_plan'] = _ApiClient.default.convertToType(data['distribution_plan'], 'String');
      }
      if (data.hasOwnProperty('visitCount')) {
        obj['visitCount'] = _ApiClient.default.convertToType(data['visitCount'], 'Number');
      }
      if (data.hasOwnProperty('wisdropCount')) {
        obj['wisdropCount'] = _ApiClient.default.convertToType(data['wisdropCount'], 'Number');
      }
      if (data.hasOwnProperty('fullReportCount')) {
        obj['fullReportCount'] = _ApiClient.default.convertToType(data['fullReportCount'], 'Number');
      }
      if (data.hasOwnProperty('webinarCount')) {
        obj['webinarCount'] = _ApiClient.default.convertToType(data['webinarCount'], 'Number');
      }
      if (data.hasOwnProperty('inListCount')) {
        obj['inListCount'] = _ApiClient.default.convertToType(data['inListCount'], 'Number');
      }
      if (data.hasOwnProperty('inLikesCount')) {
        obj['inLikesCount'] = _ApiClient.default.convertToType(data['inLikesCount'], 'Number');
      }
      if (data.hasOwnProperty('inDislikesCount')) {
        obj['inDislikesCount'] = _ApiClient.default.convertToType(data['inDislikesCount'], 'Number');
      }
      if (data.hasOwnProperty('feedbackCount')) {
        obj['feedbackCount'] = _ApiClient.default.convertToType(data['feedbackCount'], 'Number');
      }
      if (data.hasOwnProperty('users_downloaded')) {
        obj['users_downloaded'] = _ApiClient.default.convertToType(data['users_downloaded'], 'Number');
      }
      if (data.hasOwnProperty('customers_downloaded')) {
        obj['customers_downloaded'] = _ApiClient.default.convertToType(data['customers_downloaded'], 'Number');
      }
      if (data.hasOwnProperty('slides_downloaded')) {
        obj['slides_downloaded'] = _ApiClient.default.convertToType(data['slides_downloaded'], 'Number');
      }
      if (data.hasOwnProperty('files_downloaded')) {
        obj['files_downloaded'] = _ApiClient.default.convertToType(data['files_downloaded'], 'Number');
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = _ApiClient.default.convertToType(data['created_at'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GetFullReportDetailsV2Result</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetFullReportDetailsV2Result</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
      throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
    }
    // ensure the json data is a string
    if (data['country_id'] && !(typeof data['country_id'] === 'string' || data['country_id'] instanceof String)) {
      throw new Error("Expected the field `country_id` to be a primitive type in the JSON string but got " + data['country_id']);
    }
    // ensure the json data is a string
    if (data['distribution_plan_id'] && !(typeof data['distribution_plan_id'] === 'string' || data['distribution_plan_id'] instanceof String)) {
      throw new Error("Expected the field `distribution_plan_id` to be a primitive type in the JSON string but got " + data['distribution_plan_id']);
    }
    // ensure the json data is a string
    if (data['distribution_plan'] && !(typeof data['distribution_plan'] === 'string' || data['distribution_plan'] instanceof String)) {
      throw new Error("Expected the field `distribution_plan` to be a primitive type in the JSON string but got " + data['distribution_plan']);
    }
    return true;
  }
}

/**
 * @member {String} id
 */
GetFullReportDetailsV2Result.prototype['id'] = undefined;

/**
 * @member {String} title
 */
GetFullReportDetailsV2Result.prototype['title'] = undefined;

/**
 * @member {String} country_id
 */
GetFullReportDetailsV2Result.prototype['country_id'] = undefined;

/**
 * @member {String} available_from
 */
GetFullReportDetailsV2Result.prototype['available_from'] = undefined;

/**
 * @member {String} distribution_plan_id
 */
GetFullReportDetailsV2Result.prototype['distribution_plan_id'] = undefined;

/**
 * @member {Number} season_number
 */
GetFullReportDetailsV2Result.prototype['season_number'] = undefined;

/**
 * @member {String} distribution_plan
 */
GetFullReportDetailsV2Result.prototype['distribution_plan'] = undefined;

/**
 * @member {Number} visitCount
 */
GetFullReportDetailsV2Result.prototype['visitCount'] = undefined;

/**
 * @member {Number} wisdropCount
 */
GetFullReportDetailsV2Result.prototype['wisdropCount'] = undefined;

/**
 * @member {Number} fullReportCount
 */
GetFullReportDetailsV2Result.prototype['fullReportCount'] = undefined;

/**
 * @member {Number} webinarCount
 */
GetFullReportDetailsV2Result.prototype['webinarCount'] = undefined;

/**
 * @member {Number} inListCount
 */
GetFullReportDetailsV2Result.prototype['inListCount'] = undefined;

/**
 * @member {Number} inLikesCount
 */
GetFullReportDetailsV2Result.prototype['inLikesCount'] = undefined;

/**
 * @member {Number} inDislikesCount
 */
GetFullReportDetailsV2Result.prototype['inDislikesCount'] = undefined;

/**
 * @member {Number} feedbackCount
 */
GetFullReportDetailsV2Result.prototype['feedbackCount'] = undefined;

/**
 * @member {Number} users_downloaded
 */
GetFullReportDetailsV2Result.prototype['users_downloaded'] = undefined;

/**
 * @member {Number} customers_downloaded
 */
GetFullReportDetailsV2Result.prototype['customers_downloaded'] = undefined;

/**
 * @member {Number} slides_downloaded
 */
GetFullReportDetailsV2Result.prototype['slides_downloaded'] = undefined;

/**
 * @member {Number} files_downloaded
 */
GetFullReportDetailsV2Result.prototype['files_downloaded'] = undefined;

/**
 * @member {String} created_at
 */
GetFullReportDetailsV2Result.prototype['created_at'] = undefined;
var _default = GetFullReportDetailsV2Result;
exports.default = _default;