"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ManageUserContactCommand model module.
 * @module model/ManageUserContactCommand
 * @version 1.1.3-alpha.1
 */
class ManageUserContactCommand {
  /**
   * Constructs a new <code>ManageUserContactCommand</code>.
   * @alias module:model/ManageUserContactCommand
   * @param user_id {String} 
   */
  constructor(user_id) {
    ManageUserContactCommand.initialize(this, user_id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, user_id) {
    obj['user_id'] = user_id;
  }

  /**
   * Constructs a <code>ManageUserContactCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ManageUserContactCommand} obj Optional instance to populate.
   * @return {module:model/ManageUserContactCommand} The populated <code>ManageUserContactCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManageUserContactCommand();
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('is_suspended')) {
        obj['is_suspended'] = _ApiClient.default.convertToType(data['is_suspended'], 'Boolean');
      }
      if (data.hasOwnProperty('is_admin')) {
        obj['is_admin'] = _ApiClient.default.convertToType(data['is_admin'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ManageUserContactCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ManageUserContactCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ManageUserContactCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    return true;
  }
}
ManageUserContactCommand.RequiredProperties = ["user_id"];

/**
 * @member {String} user_id
 */
ManageUserContactCommand.prototype['user_id'] = undefined;

/**
 * @member {Boolean} is_suspended
 */
ManageUserContactCommand.prototype['is_suspended'] = undefined;

/**
 * @member {Boolean} is_admin
 */
ManageUserContactCommand.prototype['is_admin'] = undefined;
var _default = ManageUserContactCommand;
exports.default = _default;