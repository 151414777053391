"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MailgunClientInfo model module.
 * @module model/MailgunClientInfo
 * @version 1.1.3-alpha.1
 */
class MailgunClientInfo {
  /**
   * Constructs a new <code>MailgunClientInfo</code>.
   * @alias module:model/MailgunClientInfo
   */
  constructor() {
    MailgunClientInfo.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MailgunClientInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MailgunClientInfo} obj Optional instance to populate.
   * @return {module:model/MailgunClientInfo} The populated <code>MailgunClientInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MailgunClientInfo();
      if (data.hasOwnProperty('client-type')) {
        obj['client-type'] = _ApiClient.default.convertToType(data['client-type'], 'String');
      }
      if (data.hasOwnProperty('client-os')) {
        obj['client-os'] = _ApiClient.default.convertToType(data['client-os'], 'String');
      }
      if (data.hasOwnProperty('device-type')) {
        obj['device-type'] = _ApiClient.default.convertToType(data['device-type'], 'String');
      }
      if (data.hasOwnProperty('client-name')) {
        obj['client-name'] = _ApiClient.default.convertToType(data['client-name'], 'String');
      }
      if (data.hasOwnProperty('user-agent')) {
        obj['user-agent'] = _ApiClient.default.convertToType(data['user-agent'], 'String');
      }
      if (data.hasOwnProperty('bot')) {
        obj['bot'] = _ApiClient.default.convertToType(data['bot'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MailgunClientInfo</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MailgunClientInfo</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['client-type'] && !(typeof data['client-type'] === 'string' || data['client-type'] instanceof String)) {
      throw new Error("Expected the field `client-type` to be a primitive type in the JSON string but got " + data['client-type']);
    }
    // ensure the json data is a string
    if (data['client-os'] && !(typeof data['client-os'] === 'string' || data['client-os'] instanceof String)) {
      throw new Error("Expected the field `client-os` to be a primitive type in the JSON string but got " + data['client-os']);
    }
    // ensure the json data is a string
    if (data['device-type'] && !(typeof data['device-type'] === 'string' || data['device-type'] instanceof String)) {
      throw new Error("Expected the field `device-type` to be a primitive type in the JSON string but got " + data['device-type']);
    }
    // ensure the json data is a string
    if (data['client-name'] && !(typeof data['client-name'] === 'string' || data['client-name'] instanceof String)) {
      throw new Error("Expected the field `client-name` to be a primitive type in the JSON string but got " + data['client-name']);
    }
    // ensure the json data is a string
    if (data['user-agent'] && !(typeof data['user-agent'] === 'string' || data['user-agent'] instanceof String)) {
      throw new Error("Expected the field `user-agent` to be a primitive type in the JSON string but got " + data['user-agent']);
    }
    // ensure the json data is a string
    if (data['bot'] && !(typeof data['bot'] === 'string' || data['bot'] instanceof String)) {
      throw new Error("Expected the field `bot` to be a primitive type in the JSON string but got " + data['bot']);
    }
    return true;
  }
}

/**
 * @member {String} client-type
 */
MailgunClientInfo.prototype['client-type'] = undefined;

/**
 * @member {String} client-os
 */
MailgunClientInfo.prototype['client-os'] = undefined;

/**
 * @member {String} device-type
 */
MailgunClientInfo.prototype['device-type'] = undefined;

/**
 * @member {String} client-name
 */
MailgunClientInfo.prototype['client-name'] = undefined;

/**
 * @member {String} user-agent
 */
MailgunClientInfo.prototype['user-agent'] = undefined;

/**
 * @member {String} bot
 */
MailgunClientInfo.prototype['bot'] = undefined;
var _default = MailgunClientInfo;
exports.default = _default;