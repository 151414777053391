"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UpdateCustomerAddressCommand model module.
 * @module model/UpdateCustomerAddressCommand
 * @version 1.1.3-alpha.1
 */
class UpdateCustomerAddressCommand {
  /**
   * Constructs a new <code>UpdateCustomerAddressCommand</code>.
   * @alias module:model/UpdateCustomerAddressCommand
   * @param id {Number} 
   * @param address {String} 
   * @param region {String} 
   * @param is_billing {Boolean} 
   * @param is_delivery {Boolean} 
   */
  constructor(id, address, region, is_billing, is_delivery) {
    UpdateCustomerAddressCommand.initialize(this, id, address, region, is_billing, is_delivery);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, address, region, is_billing, is_delivery) {
    obj['id'] = id;
    obj['address'] = address;
    obj['region'] = region;
    obj['is_billing'] = is_billing;
    obj['is_delivery'] = is_delivery;
  }

  /**
   * Constructs a <code>UpdateCustomerAddressCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UpdateCustomerAddressCommand} obj Optional instance to populate.
   * @return {module:model/UpdateCustomerAddressCommand} The populated <code>UpdateCustomerAddressCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UpdateCustomerAddressCommand();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('is_default')) {
        obj['is_default'] = _ApiClient.default.convertToType(data['is_default'], 'Boolean');
      }
      if (data.hasOwnProperty('address')) {
        obj['address'] = _ApiClient.default.convertToType(data['address'], 'String');
      }
      if (data.hasOwnProperty('region')) {
        obj['region'] = _ApiClient.default.convertToType(data['region'], 'String');
      }
      if (data.hasOwnProperty('postal_code')) {
        obj['postal_code'] = _ApiClient.default.convertToType(data['postal_code'], 'String');
      }
      if (data.hasOwnProperty('is_billing')) {
        obj['is_billing'] = _ApiClient.default.convertToType(data['is_billing'], 'Boolean');
      }
      if (data.hasOwnProperty('is_delivery')) {
        obj['is_delivery'] = _ApiClient.default.convertToType(data['is_delivery'], 'Boolean');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UpdateCustomerAddressCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UpdateCustomerAddressCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of UpdateCustomerAddressCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['address'] && !(typeof data['address'] === 'string' || data['address'] instanceof String)) {
      throw new Error("Expected the field `address` to be a primitive type in the JSON string but got " + data['address']);
    }
    // ensure the json data is a string
    if (data['region'] && !(typeof data['region'] === 'string' || data['region'] instanceof String)) {
      throw new Error("Expected the field `region` to be a primitive type in the JSON string but got " + data['region']);
    }
    // ensure the json data is a string
    if (data['postal_code'] && !(typeof data['postal_code'] === 'string' || data['postal_code'] instanceof String)) {
      throw new Error("Expected the field `postal_code` to be a primitive type in the JSON string but got " + data['postal_code']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    return true;
  }
}
UpdateCustomerAddressCommand.RequiredProperties = ["id", "address", "region", "is_billing", "is_delivery"];

/**
 * @member {Number} id
 */
UpdateCustomerAddressCommand.prototype['id'] = undefined;

/**
 * @member {Boolean} is_default
 */
UpdateCustomerAddressCommand.prototype['is_default'] = undefined;

/**
 * @member {String} address
 */
UpdateCustomerAddressCommand.prototype['address'] = undefined;

/**
 * @member {String} region
 */
UpdateCustomerAddressCommand.prototype['region'] = undefined;

/**
 * @member {String} postal_code
 */
UpdateCustomerAddressCommand.prototype['postal_code'] = undefined;

/**
 * @member {Boolean} is_billing
 */
UpdateCustomerAddressCommand.prototype['is_billing'] = undefined;

/**
 * @member {Boolean} is_delivery
 */
UpdateCustomerAddressCommand.prototype['is_delivery'] = undefined;

/**
 * @member {String} description
 */
UpdateCustomerAddressCommand.prototype['description'] = undefined;
var _default = UpdateCustomerAddressCommand;
exports.default = _default;