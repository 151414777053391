"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _AnalyticsSummaryQuery = _interopRequireDefault(require("../model/AnalyticsSummaryQuery"));
var _AnalyticsSummaryRequest = _interopRequireDefault(require("../model/AnalyticsSummaryRequest"));
var _AnalyticsSummaryResult = _interopRequireDefault(require("../model/AnalyticsSummaryResult"));
var _BaseResult = _interopRequireDefault(require("../model/BaseResult"));
var _FullReportStatsQuery = _interopRequireDefault(require("../model/FullReportStatsQuery"));
var _GetFullReportDetailsV2Result = _interopRequireDefault(require("../model/GetFullReportDetailsV2Result"));
var _LogUserActionModel = _interopRequireDefault(require("../model/LogUserActionModel"));
var _ReportStatistics = _interopRequireDefault(require("../model/ReportStatistics"));
var _ReportStatisticsQuery = _interopRequireDefault(require("../model/ReportStatisticsQuery"));
var _UserAction = _interopRequireDefault(require("../model/UserAction"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Stats service.
* @module api/StatsApi
* @version 1.1.3-alpha.1
*/
class StatsApi {
  /**
  * Constructs a new StatsApi. 
  * @alias module:api/StatsApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Download analytics user actions
   * Download analytics user actions
   * @param {Object} opts Optional parameters
   * @param {module:model/AnalyticsSummaryQuery} opts.AnalyticsSummaryQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  downloadAnalyticsUserActionsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['AnalyticsSummaryQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = File;
    return this.apiClient.callApi('/api/v1/Stats/analytics/useractions/export', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Download analytics user actions
   * Download analytics user actions
   * @param {Object} opts Optional parameters
   * @param {module:model/AnalyticsSummaryQuery} opts.AnalyticsSummaryQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  downloadAnalyticsUserActions(opts) {
    return this.downloadAnalyticsUserActionsWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get full report data in excel format
   * Gets full report statistics (times clicked, times liked, times disliked etc) in excel format
   * @param {Object} opts Optional parameters
   * @param {module:model/FullReportStatsQuery} opts.FullReportStatsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  downloadFullReportWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['FullReportStatsQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = File;
    return this.apiClient.callApi('/api/v1/Stats/fullreport/download', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get full report data in excel format
   * Gets full report statistics (times clicked, times liked, times disliked etc) in excel format
   * @param {Object} opts Optional parameters
   * @param {module:model/FullReportStatsQuery} opts.FullReportStatsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  downloadFullReport(opts) {
    return this.downloadFullReportWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get report feedback data in excel format
   * Gets report feedback in excel format
   * @param {String} report_id Id of the study for which we download feedback
   * @param {Object} opts Optional parameters
   * @param {module:model/FullReportStatsQuery} opts.FullReportStatsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
   */
  downloadReportFeedbackWithHttpInfo(report_id, opts) {
    opts = opts || {};
    let postBody = opts['FullReportStatsQuery'];
    // verify the required parameter 'report_id' is set
    if (report_id === undefined || report_id === null) {
      throw new Error("Missing the required parameter 'report_id' when calling downloadReportFeedback");
    }
    let pathParams = {
      'report_id': report_id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = File;
    return this.apiClient.callApi('/api/v1/Stats/feedback/{report_id}/download', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get report feedback data in excel format
   * Gets report feedback in excel format
   * @param {String} report_id Id of the study for which we download feedback
   * @param {Object} opts Optional parameters
   * @param {module:model/FullReportStatsQuery} opts.FullReportStatsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
   */
  downloadReportFeedback(report_id, opts) {
    return this.downloadReportFeedbackWithHttpInfo(report_id, opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get analytics summary
   * Get analytics summary
   * @param {Object} opts Optional parameters
   * @param {module:model/AnalyticsSummaryRequest} opts.AnalyticsSummaryRequest 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AnalyticsSummaryResult} and HTTP response
   */
  getAnalyticsSummaryWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['AnalyticsSummaryRequest'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _AnalyticsSummaryResult.default;
    return this.apiClient.callApi('/api/v1/Stats/analytics/summary', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get analytics summary
   * Get analytics summary
   * @param {Object} opts Optional parameters
   * @param {module:model/AnalyticsSummaryRequest} opts.AnalyticsSummaryRequest 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AnalyticsSummaryResult}
   */
  getAnalyticsSummary(opts) {
    return this.getAnalyticsSummaryWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get analytics user actions
   * Get analytics user actions
   * @param {Object} opts Optional parameters
   * @param {module:model/AnalyticsSummaryRequest} opts.AnalyticsSummaryRequest 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/UserAction>} and HTTP response
   */
  getAnalyticsUserActionsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['AnalyticsSummaryRequest'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_UserAction.default];
    return this.apiClient.callApi('/api/v1/Stats/analytics/useractions', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get analytics user actions
   * Get analytics user actions
   * @param {Object} opts Optional parameters
   * @param {module:model/AnalyticsSummaryRequest} opts.AnalyticsSummaryRequest 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/UserAction>}
   */
  getAnalyticsUserActions(opts) {
    return this.getAnalyticsUserActionsWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get full report data
   * Gets full report statistics (times clicked, times liked, times disliked etc)
   * @param {Object} opts Optional parameters
   * @param {module:model/FullReportStatsQuery} opts.FullReportStatsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/GetFullReportDetailsV2Result>} and HTTP response
   */
  getFullReportWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['FullReportStatsQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = [_GetFullReportDetailsV2Result.default];
    return this.apiClient.callApi('/api/v1/Stats/fullreport', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get full report data
   * Gets full report statistics (times clicked, times liked, times disliked etc)
   * @param {Object} opts Optional parameters
   * @param {module:model/FullReportStatsQuery} opts.FullReportStatsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/GetFullReportDetailsV2Result>}
   */
  getFullReport(opts) {
    return this.getFullReportWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Get reporting data
   * Gets reporting data (penetration, adoption rate, top users/reports/interests etc
   * @param {Object} opts Optional parameters
   * @param {module:model/ReportStatisticsQuery} opts.ReportStatisticsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportStatistics} and HTTP response
   */
  getReportWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['ReportStatisticsQuery'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _ReportStatistics.default;
    return this.apiClient.callApi('/api/v1/Stats/report', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Get reporting data
   * Gets reporting data (penetration, adoption rate, top users/reports/interests etc
   * @param {Object} opts Optional parameters
   * @param {module:model/ReportStatisticsQuery} opts.ReportStatisticsQuery 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportStatistics}
   */
  getReport(opts) {
    return this.getReportWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Log entity view
   * Logs the view of an entity's section by the current user. If the section is already viewed it is not logged again
   * @param {String} id Id of the entity that is viewed
   * @param {String} section Section of the entity that is viewed
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  logEntityViewedWithHttpInfo(id, section) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling logEntityViewed");
    }
    // verify the required parameter 'section' is set
    if (section === undefined || section === null) {
      throw new Error("Missing the required parameter 'section' when calling logEntityViewed");
    }
    let pathParams = {
      'id': id,
      'section': section
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Stats/view/{id}/{section}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Log entity view
   * Logs the view of an entity's section by the current user. If the section is already viewed it is not logged again
   * @param {String} id Id of the entity that is viewed
   * @param {String} section Section of the entity that is viewed
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  logEntityViewed(id, section) {
    return this.logEntityViewedWithHttpInfo(id, section).then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Logs user presence
   * Logs the user's online presence. This method is supposed to be called periodically (about once per minute) to log online status and time spent on site
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  logIAmOnlineWithHttpInfo() {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Stats/iamonline', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Logs user presence
   * Logs the user's online presence. This method is supposed to be called periodically (about once per minute) to log online status and time spent on site
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  logIAmOnline() {
    return this.logIAmOnlineWithHttpInfo().then(function (response_and_data) {
      return response_and_data.data;
    });
  }

  /**
   * Logs user action
   * Logs a user action (for statistics purposes)
   * @param {Object} opts Optional parameters
   * @param {module:model/LogUserActionModel} opts.LogUserActionModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BaseResult} and HTTP response
   */
  logUserActionWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = opts['LogUserActionModel'];
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Bearer'];
    let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    let accepts = ['application/json'];
    let returnType = _BaseResult.default;
    return this.apiClient.callApi('/api/v1/Stats/action', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null);
  }

  /**
   * Logs user action
   * Logs a user action (for statistics purposes)
   * @param {Object} opts Optional parameters
   * @param {module:model/LogUserActionModel} opts.LogUserActionModel 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BaseResult}
   */
  logUserAction(opts) {
    return this.logUserActionWithHttpInfo(opts).then(function (response_and_data) {
      return response_and_data.data;
    });
  }
}
exports.default = StatsApi;