import { ACCOUNT } from '@extend/paywall-api/src/resource-types';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Tooltip,
	Typography,
	makeStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import { FormProvider, useController, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Input } from 'reactstrap';
import { usePaywallApi } from '../hooks/paywall-api';
import { useNotifications } from './notifications';

export const InviteColleagueButton = props => {
	const [isModalOpen, setModalOpen] = useState(false);
	const onClose = () => setModalOpen(false);
	return (
		<>
			<Tooltip title="Invite your colleagues">
				<PersonAddRoundedIcon fontSize="inherit" {...props} onClick={() => setModalOpen(true)} />
			</Tooltip>
			<InviteDialog open={isModalOpen} onClose={onClose} />
		</>
	);
};

const emailValidation = {
	required: 'Email is required',
	pattern: {
		value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
		message: 'Invalid email address'
	}
};

function InviteDialog({ open, onClose }) {
	const form = useForm({ mode: 'onBlur', reValidateMode: 'onBlur', defaultValues: { emails: [''] } });
	const classes = inviteColleaguesStyles();
	const { handleSubmit } = form;
	const api = usePaywallApi();
	const { error, success } = useNotifications();

	const { mutateAsync, isLoading } = useMutation({
		mutationFn: async values => {
			try {
				const accountApi = await api.resolveApi(ACCOUNT);
				return await accountApi.invite(values);
			} catch (e) {
				console.log('Error while sending invitations', e);
				throw e;
			}
		},
		onSuccess: () => {
			onClose();
			success({
				title: 'Success',
				message: 'Email invitations have been sent successfully'
			});
		},
		onError: e => {
			error({
				title: 'Error',
				message: 'There was en error trying to send email invitations'
			});
		}
	});

	return (
		<FormProvider {...form}>
			<Dialog open={open} onClose={onClose} classes={classes}>
				<DialogTitle disableTypography className="invite-title">
					<Typography variant="h2">Invite your colleagues</Typography>
					<IconButton aria-label="close" size="medium" className="close-button" onClick={onClose}>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				</DialogTitle>
				<DialogTitle className="invite-subtitle">(it's included in your plan!)</DialogTitle>
				<form onSubmit={handleSubmit(mutateAsync)}>
					<DialogContent className="invite-body">
						<div class="invite-icon">
							<PersonAddRoundedIcon fontSize="inherit" />
						</div>
						<div>
							<Typography variant="body1">Send invites by email</Typography>
						</div>
						<InviteEmailFields />
					</DialogContent>
					<DialogContent className="invite-toolbar">
						<Button
							className="invite-send-button"
							size="large"
							variant="contained"
							color="primary"
							disableElevation
							type="submit"
							disabled={isLoading}>
							Send Invites
						</Button>
					</DialogContent>
				</form>
			</Dialog>
		</FormProvider>
	);
}

const InviteEmailFields = () => {
	const emails = useWatch({ name: 'emails' });
	const { setValue, getValues } = useFormContext();

	// a method that removes an item at the given index
	const remove = index => {
		var emails = getValues('emails');
		if (emails.length > 1) {
			setValue(
				'emails',
				emails.filter((_, i) => i !== index)
			);
		}
	};

	const add = () => {
		var emails = getValues('emails');
		setValue('emails', [...emails, '']);
	};

	return (
		<>
			{(emails || []).map((field, index) => (
				<EmailInput
					key={index}
					index={index}
					fieldName={`emails.${index}`}
					remove={() => (emails.length > 1 ? remove(index) : null)}
					onlyEmail={emails.length === 1}
				/>
			))}
			<div className="email-add-row">
				<Button
					className="email-add-button"
					disableElevation
					startIcon={<AddIcon htmlColor="black" />}
					size="large"
					onClick={() => add()}>
					Add More
				</Button>
				<div>
					<IconButton size="small" onClick={null}>
						<AddIcon htmlColor="transparent" />
					</IconButton>
				</div>
			</div>
		</>
	);
};

function EmailInput({ index, fieldName, onlyEmail, remove }) {
	const {
		field,
		fieldState: { invalid, error }
	} = useController({
		name: fieldName,
		rules: emailValidation,
		shouldUnregister: true
	});
	return (
		<Fragment>
			<div className={clsx('email-input-row', { invalid: invalid })}>
				<Input {...field} ref={field.ref} className="email-input" placeholder="email" type="email" />
				<div>
					<IconButton size="small" onClick={remove} tabIndex={-1}>
						<RemoveCircleOutlineIcon htmlColor={onlyEmail ? 'transparent' : 'red'} />
					</IconButton>
				</div>
			</div>
			{error && <div className="email-error-row">{error.message}</div>}
		</Fragment>
	);
}

const inviteColleaguesStyles = makeStyles(theme => ({
	root: {
		'& .invite-title': {
			'paddingBottom': 0,
			'& .MuiTypography-root': {
				fontFamily: 'Helvetica-Bold',
				fontSize: '1.8vw',
				paddingRight: '60px',
				whiteSpace: 'nowrap'
			},
			'& .close-button': {
				'fontSize': '1.3vw',
				'position': 'absolute',
				'right': theme.spacing(1),
				'top': theme.spacing(1),
				'color': 'black',
				'& .MuiIconButton-label': {
					border: '1px sold black',
					borderColor: 'black',
					borderRadius: '50%',
					borderStyle: 'solid',
					borderWidth: '1px',
					padding: '0.2vw'
				}
			}
		},
		'& .invite-subtitle': {
			'paddingTop': 0,
			'paddingBottom': 0,
			'marginTop': -5,
			'& .MuiTypography-root': { fontSize: '1vw' }
		},
		'& .invite-body': {
			'display': 'flex',
			'flexDirection': 'column',
			// justifyContent: 'center',
			'alignItems': 'stretch',
			'paddingTop': 0,
			'& .invite-icon': {
				'textAlign': 'center',
				'fontSize': '5vw',
				'& .MuiSvgIcon-fontSizeInherit': { fontSize: '5vw' }
			},
			'& .email-input-row': {
				'display': 'flex',
				'alignItems': 'center',
				'paddingBottom': '0.2vw',
				'& .email-input': {
					flexGrow: 1
				},
				'&.invalid .email-input': {
					color: 'red',
					borderColor: 'red'
				}
			},
			'& .email-add-row': {
				'display': 'flex',
				'alignItems': 'center',
				'justifyContent': 'stretch',
				'paddingTop': '0.2vw',
				'paddingRight': '0.2vw',
				'& .email-add-button': {
					backgroundColor: 'white',
					width: '100%',
					color: 'black',
					borderRadius: '0.5vw'
				}
			},
			'& .email-error-row': {
				color: 'red',
				fontSize: '0.7vw'
			},
			'& .email-input': {
				backgroundColor: 'white',
				color: 'black',
				border: '1px solid black',
				borderRadius: '0.5vw',
				fontSize: '1.2em',
				marginRight: 5
			}
		},
		'& .invite-toolbar': {
			'display': 'flex',
			'justifyContent': 'end',
			'alignItems': 'center',
			'paddingRight': 60,
			'& .invite-send-button': {
				borderRadius: '0.5vw'
			}
		}
	},
	paper: { color: 'black', backgroundColor: 'white', borderRadius: '10px' }
}));
