"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ContentProtectionToken model module.
 * @module model/ContentProtectionToken
 * @version 1.1.3-alpha.1
 */
class ContentProtectionToken {
  /**
   * Constructs a new <code>ContentProtectionToken</code>.
   * @alias module:model/ContentProtectionToken
   * @param id {String} 
   * @param content_id {String} 
   * @param issuer_id {String} 
   * @param issued_at {String} 
   * @param valid_for {String} 
   */
  constructor(id, content_id, issuer_id, issued_at, valid_for) {
    ContentProtectionToken.initialize(this, id, content_id, issuer_id, issued_at, valid_for);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, content_id, issuer_id, issued_at, valid_for) {
    obj['id'] = id;
    obj['content_id'] = content_id;
    obj['issuer_id'] = issuer_id;
    obj['issued_at'] = issued_at;
    obj['valid_for'] = valid_for;
  }

  /**
   * Constructs a <code>ContentProtectionToken</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ContentProtectionToken} obj Optional instance to populate.
   * @return {module:model/ContentProtectionToken} The populated <code>ContentProtectionToken</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ContentProtectionToken();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('content_id')) {
        obj['content_id'] = _ApiClient.default.convertToType(data['content_id'], 'String');
      }
      if (data.hasOwnProperty('issuer_id')) {
        obj['issuer_id'] = _ApiClient.default.convertToType(data['issuer_id'], 'String');
      }
      if (data.hasOwnProperty('issued_at')) {
        obj['issued_at'] = _ApiClient.default.convertToType(data['issued_at'], 'String');
      }
      if (data.hasOwnProperty('valid_for')) {
        obj['valid_for'] = _ApiClient.default.convertToType(data['valid_for'], 'String');
      }
      if (data.hasOwnProperty('expires_at')) {
        obj['expires_at'] = _ApiClient.default.convertToType(data['expires_at'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ContentProtectionToken</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ContentProtectionToken</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ContentProtectionToken.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['content_id'] && !(typeof data['content_id'] === 'string' || data['content_id'] instanceof String)) {
      throw new Error("Expected the field `content_id` to be a primitive type in the JSON string but got " + data['content_id']);
    }
    // ensure the json data is a string
    if (data['issuer_id'] && !(typeof data['issuer_id'] === 'string' || data['issuer_id'] instanceof String)) {
      throw new Error("Expected the field `issuer_id` to be a primitive type in the JSON string but got " + data['issuer_id']);
    }
    // ensure the json data is a string
    if (data['valid_for'] && !(typeof data['valid_for'] === 'string' || data['valid_for'] instanceof String)) {
      throw new Error("Expected the field `valid_for` to be a primitive type in the JSON string but got " + data['valid_for']);
    }
    return true;
  }
}
ContentProtectionToken.RequiredProperties = ["id", "content_id", "issuer_id", "issued_at", "valid_for"];

/**
 * @member {String} id
 */
ContentProtectionToken.prototype['id'] = undefined;

/**
 * @member {String} content_id
 */
ContentProtectionToken.prototype['content_id'] = undefined;

/**
 * @member {String} issuer_id
 */
ContentProtectionToken.prototype['issuer_id'] = undefined;

/**
 * @member {String} issued_at
 */
ContentProtectionToken.prototype['issued_at'] = undefined;

/**
 * @member {String} valid_for
 */
ContentProtectionToken.prototype['valid_for'] = undefined;

/**
 * @member {String} expires_at
 */
ContentProtectionToken.prototype['expires_at'] = undefined;
var _default = ContentProtectionToken;
exports.default = _default;