"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CreateCustomerMediaCommand model module.
 * @module model/CreateCustomerMediaCommand
 * @version 1.1.3-alpha.1
 */
class CreateCustomerMediaCommand {
  /**
   * Constructs a new <code>CreateCustomerMediaCommand</code>.
   * @alias module:model/CreateCustomerMediaCommand
   * @param title {String} 
   * @param category_id {String} 
   * @param release_year {Number} 
   * @param release_month {Number} 
   */
  constructor(title, category_id, release_year, release_month) {
    CreateCustomerMediaCommand.initialize(this, title, category_id, release_year, release_month);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, title, category_id, release_year, release_month) {
    obj['title'] = title;
    obj['category_id'] = category_id;
    obj['release_year'] = release_year;
    obj['release_month'] = release_month;
  }

  /**
   * Constructs a <code>CreateCustomerMediaCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CreateCustomerMediaCommand} obj Optional instance to populate.
   * @return {module:model/CreateCustomerMediaCommand} The populated <code>CreateCustomerMediaCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateCustomerMediaCommand();
      if (data.hasOwnProperty('title')) {
        obj['title'] = _ApiClient.default.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('category_id')) {
        obj['category_id'] = _ApiClient.default.convertToType(data['category_id'], 'String');
      }
      if (data.hasOwnProperty('slide_ids')) {
        obj['slide_ids'] = _ApiClient.default.convertToType(data['slide_ids'], ['String']);
      }
      if (data.hasOwnProperty('file_ids')) {
        obj['file_ids'] = _ApiClient.default.convertToType(data['file_ids'], ['String']);
      }
      if (data.hasOwnProperty('tag_ids')) {
        obj['tag_ids'] = _ApiClient.default.convertToType(data['tag_ids'], ['Number']);
      }
      if (data.hasOwnProperty('release_year')) {
        obj['release_year'] = _ApiClient.default.convertToType(data['release_year'], 'Number');
      }
      if (data.hasOwnProperty('release_month')) {
        obj['release_month'] = _ApiClient.default.convertToType(data['release_month'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CreateCustomerMediaCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CreateCustomerMediaCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CreateCustomerMediaCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
      throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
    }
    // ensure the json data is a string
    if (data['category_id'] && !(typeof data['category_id'] === 'string' || data['category_id'] instanceof String)) {
      throw new Error("Expected the field `category_id` to be a primitive type in the JSON string but got " + data['category_id']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['slide_ids'])) {
      throw new Error("Expected the field `slide_ids` to be an array in the JSON data but got " + data['slide_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['file_ids'])) {
      throw new Error("Expected the field `file_ids` to be an array in the JSON data but got " + data['file_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['tag_ids'])) {
      throw new Error("Expected the field `tag_ids` to be an array in the JSON data but got " + data['tag_ids']);
    }
    return true;
  }
}
CreateCustomerMediaCommand.RequiredProperties = ["title", "category_id", "release_year", "release_month"];

/**
 * @member {String} title
 */
CreateCustomerMediaCommand.prototype['title'] = undefined;

/**
 * @member {String} category_id
 */
CreateCustomerMediaCommand.prototype['category_id'] = undefined;

/**
 * @member {Array.<String>} slide_ids
 */
CreateCustomerMediaCommand.prototype['slide_ids'] = undefined;

/**
 * @member {Array.<String>} file_ids
 */
CreateCustomerMediaCommand.prototype['file_ids'] = undefined;

/**
 * @member {Array.<Number>} tag_ids
 */
CreateCustomerMediaCommand.prototype['tag_ids'] = undefined;

/**
 * @member {Number} release_year
 */
CreateCustomerMediaCommand.prototype['release_year'] = undefined;

/**
 * @member {Number} release_month
 */
CreateCustomerMediaCommand.prototype['release_month'] = undefined;
var _default = CreateCustomerMediaCommand;
exports.default = _default;