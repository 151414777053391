"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DecimalAnalyticsItem = _interopRequireDefault(require("./DecimalAnalyticsItem"));
var _Int32AnalyticsItem = _interopRequireDefault(require("./Int32AnalyticsItem"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AnalyticsSummaryResult model module.
 * @module model/AnalyticsSummaryResult
 * @version 1.1.3-alpha.1
 */
class AnalyticsSummaryResult {
  /**
   * Constructs a new <code>AnalyticsSummaryResult</code>.
   * @alias module:model/AnalyticsSummaryResult
   */
  constructor() {
    AnalyticsSummaryResult.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>AnalyticsSummaryResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AnalyticsSummaryResult} obj Optional instance to populate.
   * @return {module:model/AnalyticsSummaryResult} The populated <code>AnalyticsSummaryResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AnalyticsSummaryResult();
      if (data.hasOwnProperty('users_activated')) {
        obj['users_activated'] = _Int32AnalyticsItem.default.constructFromObject(data['users_activated']);
      }
      if (data.hasOwnProperty('clients_visited')) {
        obj['clients_visited'] = _Int32AnalyticsItem.default.constructFromObject(data['clients_visited']);
      }
      if (data.hasOwnProperty('users_visited')) {
        obj['users_visited'] = _Int32AnalyticsItem.default.constructFromObject(data['users_visited']);
      }
      if (data.hasOwnProperty('visits_per_user')) {
        obj['visits_per_user'] = _DecimalAnalyticsItem.default.constructFromObject(data['visits_per_user']);
      }
      if (data.hasOwnProperty('time_per_visits')) {
        obj['time_per_visits'] = _Int32AnalyticsItem.default.constructFromObject(data['time_per_visits']);
      }
      if (data.hasOwnProperty('wisdrops_per_user')) {
        obj['wisdrops_per_user'] = _DecimalAnalyticsItem.default.constructFromObject(data['wisdrops_per_user']);
      }
      if (data.hasOwnProperty('reports_per_user')) {
        obj['reports_per_user'] = _DecimalAnalyticsItem.default.constructFromObject(data['reports_per_user']);
      }
      if (data.hasOwnProperty('downloads_per_user')) {
        obj['downloads_per_user'] = _DecimalAnalyticsItem.default.constructFromObject(data['downloads_per_user']);
      }
      if (data.hasOwnProperty('webinars_per_user')) {
        obj['webinars_per_user'] = _DecimalAnalyticsItem.default.constructFromObject(data['webinars_per_user']);
      }
      if (data.hasOwnProperty('searches_per_user')) {
        obj['searches_per_user'] = _DecimalAnalyticsItem.default.constructFromObject(data['searches_per_user']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AnalyticsSummaryResult</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AnalyticsSummaryResult</code>.
   */
  static validateJSON(data) {
    // validate the optional field `users_activated`
    if (data['users_activated']) {
      // data not null
      _Int32AnalyticsItem.default.validateJSON(data['users_activated']);
    }
    // validate the optional field `clients_visited`
    if (data['clients_visited']) {
      // data not null
      _Int32AnalyticsItem.default.validateJSON(data['clients_visited']);
    }
    // validate the optional field `users_visited`
    if (data['users_visited']) {
      // data not null
      _Int32AnalyticsItem.default.validateJSON(data['users_visited']);
    }
    // validate the optional field `visits_per_user`
    if (data['visits_per_user']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['visits_per_user']);
    }
    // validate the optional field `time_per_visits`
    if (data['time_per_visits']) {
      // data not null
      _Int32AnalyticsItem.default.validateJSON(data['time_per_visits']);
    }
    // validate the optional field `wisdrops_per_user`
    if (data['wisdrops_per_user']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['wisdrops_per_user']);
    }
    // validate the optional field `reports_per_user`
    if (data['reports_per_user']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['reports_per_user']);
    }
    // validate the optional field `downloads_per_user`
    if (data['downloads_per_user']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['downloads_per_user']);
    }
    // validate the optional field `webinars_per_user`
    if (data['webinars_per_user']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['webinars_per_user']);
    }
    // validate the optional field `searches_per_user`
    if (data['searches_per_user']) {
      // data not null
      _DecimalAnalyticsItem.default.validateJSON(data['searches_per_user']);
    }
    return true;
  }
}

/**
 * @member {module:model/Int32AnalyticsItem} users_activated
 */
AnalyticsSummaryResult.prototype['users_activated'] = undefined;

/**
 * @member {module:model/Int32AnalyticsItem} clients_visited
 */
AnalyticsSummaryResult.prototype['clients_visited'] = undefined;

/**
 * @member {module:model/Int32AnalyticsItem} users_visited
 */
AnalyticsSummaryResult.prototype['users_visited'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} visits_per_user
 */
AnalyticsSummaryResult.prototype['visits_per_user'] = undefined;

/**
 * @member {module:model/Int32AnalyticsItem} time_per_visits
 */
AnalyticsSummaryResult.prototype['time_per_visits'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} wisdrops_per_user
 */
AnalyticsSummaryResult.prototype['wisdrops_per_user'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} reports_per_user
 */
AnalyticsSummaryResult.prototype['reports_per_user'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} downloads_per_user
 */
AnalyticsSummaryResult.prototype['downloads_per_user'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} webinars_per_user
 */
AnalyticsSummaryResult.prototype['webinars_per_user'] = undefined;

/**
 * @member {module:model/DecimalAnalyticsItem} searches_per_user
 */
AnalyticsSummaryResult.prototype['searches_per_user'] = undefined;
var _default = AnalyticsSummaryResult;
exports.default = _default;