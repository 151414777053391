"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CommandAddMediaSlideComment model module.
 * @module model/CommandAddMediaSlideComment
 * @version 1.1.3-alpha.1
 */
class CommandAddMediaSlideComment {
  /**
   * Constructs a new <code>CommandAddMediaSlideComment</code>.
   * @alias module:model/CommandAddMediaSlideComment
   * @param slide_id {String} 
   * @param comment_text {String} 
   */
  constructor(slide_id, comment_text) {
    CommandAddMediaSlideComment.initialize(this, slide_id, comment_text);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, slide_id, comment_text) {
    obj['slide_id'] = slide_id;
    obj['comment_text'] = comment_text;
  }

  /**
   * Constructs a <code>CommandAddMediaSlideComment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CommandAddMediaSlideComment} obj Optional instance to populate.
   * @return {module:model/CommandAddMediaSlideComment} The populated <code>CommandAddMediaSlideComment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CommandAddMediaSlideComment();
      if (data.hasOwnProperty('slide_id')) {
        obj['slide_id'] = _ApiClient.default.convertToType(data['slide_id'], 'String');
      }
      if (data.hasOwnProperty('comment_text')) {
        obj['comment_text'] = _ApiClient.default.convertToType(data['comment_text'], 'String');
      }
      if (data.hasOwnProperty('parent_comment_id')) {
        obj['parent_comment_id'] = _ApiClient.default.convertToType(data['parent_comment_id'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CommandAddMediaSlideComment</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CommandAddMediaSlideComment</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CommandAddMediaSlideComment.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['slide_id'] && !(typeof data['slide_id'] === 'string' || data['slide_id'] instanceof String)) {
      throw new Error("Expected the field `slide_id` to be a primitive type in the JSON string but got " + data['slide_id']);
    }
    // ensure the json data is a string
    if (data['comment_text'] && !(typeof data['comment_text'] === 'string' || data['comment_text'] instanceof String)) {
      throw new Error("Expected the field `comment_text` to be a primitive type in the JSON string but got " + data['comment_text']);
    }
    // ensure the json data is a string
    if (data['parent_comment_id'] && !(typeof data['parent_comment_id'] === 'string' || data['parent_comment_id'] instanceof String)) {
      throw new Error("Expected the field `parent_comment_id` to be a primitive type in the JSON string but got " + data['parent_comment_id']);
    }
    return true;
  }
}
CommandAddMediaSlideComment.RequiredProperties = ["slide_id", "comment_text"];

/**
 * @member {String} slide_id
 */
CommandAddMediaSlideComment.prototype['slide_id'] = undefined;

/**
 * @member {String} comment_text
 */
CommandAddMediaSlideComment.prototype['comment_text'] = undefined;

/**
 * @member {String} parent_comment_id
 */
CommandAddMediaSlideComment.prototype['parent_comment_id'] = undefined;
var _default = CommandAddMediaSlideComment;
exports.default = _default;