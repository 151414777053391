"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The DefinedUserPreference model module.
 * @module model/DefinedUserPreference
 * @version 1.1.3-alpha.1
 */
class DefinedUserPreference {
  /**
   * Constructs a new <code>DefinedUserPreference</code>.
   * @alias module:model/DefinedUserPreference
   * @param id {Number} 
   * @param ordering {Number} 
   * @param caption {String} 
   * @param hint {String} 
   * @param value_type {Number} 
   */
  constructor(id, ordering, caption, hint, value_type) {
    DefinedUserPreference.initialize(this, id, ordering, caption, hint, value_type);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, ordering, caption, hint, value_type) {
    obj['id'] = id;
    obj['ordering'] = ordering;
    obj['caption'] = caption;
    obj['hint'] = hint;
    obj['value_type'] = value_type;
  }

  /**
   * Constructs a <code>DefinedUserPreference</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DefinedUserPreference} obj Optional instance to populate.
   * @return {module:model/DefinedUserPreference} The populated <code>DefinedUserPreference</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DefinedUserPreference();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('ordering')) {
        obj['ordering'] = _ApiClient.default.convertToType(data['ordering'], 'Number');
      }
      if (data.hasOwnProperty('caption')) {
        obj['caption'] = _ApiClient.default.convertToType(data['caption'], 'String');
      }
      if (data.hasOwnProperty('hint')) {
        obj['hint'] = _ApiClient.default.convertToType(data['hint'], 'String');
      }
      if (data.hasOwnProperty('value_type')) {
        obj['value_type'] = _ApiClient.default.convertToType(data['value_type'], 'Number');
      }
      if (data.hasOwnProperty('default_value_flag')) {
        obj['default_value_flag'] = _ApiClient.default.convertToType(data['default_value_flag'], 'Boolean');
      }
      if (data.hasOwnProperty('default_value_number')) {
        obj['default_value_number'] = _ApiClient.default.convertToType(data['default_value_number'], 'Number');
      }
      if (data.hasOwnProperty('default_value_string')) {
        obj['default_value_string'] = _ApiClient.default.convertToType(data['default_value_string'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>DefinedUserPreference</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DefinedUserPreference</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of DefinedUserPreference.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['caption'] && !(typeof data['caption'] === 'string' || data['caption'] instanceof String)) {
      throw new Error("Expected the field `caption` to be a primitive type in the JSON string but got " + data['caption']);
    }
    // ensure the json data is a string
    if (data['hint'] && !(typeof data['hint'] === 'string' || data['hint'] instanceof String)) {
      throw new Error("Expected the field `hint` to be a primitive type in the JSON string but got " + data['hint']);
    }
    // ensure the json data is a string
    if (data['default_value_string'] && !(typeof data['default_value_string'] === 'string' || data['default_value_string'] instanceof String)) {
      throw new Error("Expected the field `default_value_string` to be a primitive type in the JSON string but got " + data['default_value_string']);
    }
    return true;
  }
}
DefinedUserPreference.RequiredProperties = ["id", "ordering", "caption", "hint", "value_type"];

/**
 * @member {Number} id
 */
DefinedUserPreference.prototype['id'] = undefined;

/**
 * @member {Number} ordering
 */
DefinedUserPreference.prototype['ordering'] = undefined;

/**
 * @member {String} caption
 */
DefinedUserPreference.prototype['caption'] = undefined;

/**
 * @member {String} hint
 */
DefinedUserPreference.prototype['hint'] = undefined;

/**
 * @member {Number} value_type
 */
DefinedUserPreference.prototype['value_type'] = undefined;

/**
 * @member {Boolean} default_value_flag
 */
DefinedUserPreference.prototype['default_value_flag'] = undefined;

/**
 * @member {Number} default_value_number
 */
DefinedUserPreference.prototype['default_value_number'] = undefined;

/**
 * @member {String} default_value_string
 */
DefinedUserPreference.prototype['default_value_string'] = undefined;
var _default = DefinedUserPreference;
exports.default = _default;