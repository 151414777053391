import React, { memo } from 'react';
import { setClasses } from '../../utilities/classname-utils';
import './loading-spinners.scss';

export const Loading = memo(({ className, children, image, spinner, text, ...props }) => {
	if (spinner === undefined)
		// Αν δεν έχουμε περάσει spinner, θεωρούμε τον default
		spinner = 'dots';

	if (text === undefined)
		// Αν δεν έχουμε περάσει κείμενο, θεωρούμε το default.
		text = 'Loading... please wait...';

	// Αν κανένα από τα τρία properties δεν έχει τιμή, βάζουμε τον default spinner και βγάζουμε warning στο console.
	if (!image && !spinner && !text && !children) {
		console.warn(
			"Loading component: You can't choose to not render anything. You must choose at least an image, a spinner or some text"
		);
		spinner = 'dots';
	}

	return (
		<div className={setClasses('loading-container', className)}>
			<LoadingImage src={image} />
			<LoadingSpinner name={spinner} />
			<LoadingText text={text}>{children}</LoadingText>
		</div>
	);
});

const LoadingImage = memo(props => {
	if (!props.src) return null;

	return (
		<div className="loading-image">
			<img src={props.src} alt="..." />
		</div>
	);
});

const LoadingSpinner = memo(props => {
	var name = props.name;

	// Αν το name έχει τιμή και δεν είναι 'dots' ή 'circle' το θέτουμε σε 'dots' (το default) και βγάζουμε warning
	if (name && name !== 'dots' && name !== 'circle') {
		console.warn(`Loading component: unrecognized spinner name '${name}'. Valid values = 'dots' or 'circle'`);
		name = 'dots';
	}

	if (name === 'dots')
		return (
			<div className="loading-indicator horizontal-spinner">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		);

	if (name === 'circle') return <div className="loading-indicator spinner-border"></div>;

	return null;
});

const LoadingText = memo(({ text, children }) => {
	if (!text && !children) return null;

	return <div className="loading-text">{children || text}</div>;
});
