"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Country = _interopRequireDefault(require("./Country"));
var _DefinedUserRoles = _interopRequireDefault(require("./DefinedUserRoles"));
var _LoggedInUser = _interopRequireDefault(require("./LoggedInUser"));
var _LoginInfo = _interopRequireDefault(require("./LoginInfo"));
var _ResultCode = _interopRequireDefault(require("./ResultCode"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The LoginResult model module.
 * @module model/LoginResult
 * @version 1.1.3-alpha.1
 */
class LoginResult {
  /**
   * Constructs a new <code>LoginResult</code>.
   * @alias module:model/LoginResult
   */
  constructor() {
    LoginResult.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>LoginResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LoginResult} obj Optional instance to populate.
   * @return {module:model/LoginResult} The populated <code>LoginResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LoginResult();
      if (data.hasOwnProperty('authToken')) {
        obj['authToken'] = _ApiClient.default.convertToType(data['authToken'], 'String');
      }
      if (data.hasOwnProperty('user')) {
        obj['user'] = _LoggedInUser.default.constructFromObject(data['user']);
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('role_id')) {
        obj['role_id'] = _DefinedUserRoles.default.constructFromObject(data['role_id']);
      }
      if (data.hasOwnProperty('customer_id')) {
        obj['customer_id'] = _ApiClient.default.convertToType(data['customer_id'], 'String');
      }
      if (data.hasOwnProperty('contact_id')) {
        obj['contact_id'] = _ApiClient.default.convertToType(data['contact_id'], 'Number');
      }
      if (data.hasOwnProperty('active_subscription_id')) {
        obj['active_subscription_id'] = _ApiClient.default.convertToType(data['active_subscription_id'], 'Number');
      }
      if (data.hasOwnProperty('subscription_type_id')) {
        obj['subscription_type_id'] = _ApiClient.default.convertToType(data['subscription_type_id'], 'String');
      }
      if (data.hasOwnProperty('resources')) {
        obj['resources'] = _LoginInfo.default.constructFromObject(data['resources']);
      }
      if (data.hasOwnProperty('permissions')) {
        obj['permissions'] = _ApiClient.default.convertToType(data['permissions'], {
          'String': 'Boolean'
        });
      }
      if (data.hasOwnProperty('countries')) {
        obj['countries'] = _ApiClient.default.convertToType(data['countries'], [_Country.default]);
      }
      if (data.hasOwnProperty('code')) {
        obj['code'] = _ResultCode.default.constructFromObject(data['code']);
      }
      if (data.hasOwnProperty('success')) {
        obj['success'] = _ApiClient.default.convertToType(data['success'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>LoginResult</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>LoginResult</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['authToken'] && !(typeof data['authToken'] === 'string' || data['authToken'] instanceof String)) {
      throw new Error("Expected the field `authToken` to be a primitive type in the JSON string but got " + data['authToken']);
    }
    // validate the optional field `user`
    if (data['user']) {
      // data not null
      _LoggedInUser.default.validateJSON(data['user']);
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // ensure the json data is a string
    if (data['customer_id'] && !(typeof data['customer_id'] === 'string' || data['customer_id'] instanceof String)) {
      throw new Error("Expected the field `customer_id` to be a primitive type in the JSON string but got " + data['customer_id']);
    }
    // ensure the json data is a string
    if (data['subscription_type_id'] && !(typeof data['subscription_type_id'] === 'string' || data['subscription_type_id'] instanceof String)) {
      throw new Error("Expected the field `subscription_type_id` to be a primitive type in the JSON string but got " + data['subscription_type_id']);
    }
    // validate the optional field `resources`
    if (data['resources']) {
      // data not null
      _LoginInfo.default.validateJSON(data['resources']);
    }
    if (data['countries']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['countries'])) {
        throw new Error("Expected the field `countries` to be an array in the JSON data but got " + data['countries']);
      }
      // validate the optional field `countries` (array)
      for (const item of data['countries']) {
        _Country.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}

/**
 * @member {String} authToken
 */
LoginResult.prototype['authToken'] = undefined;

/**
 * @member {module:model/LoggedInUser} user
 */
LoginResult.prototype['user'] = undefined;

/**
 * @member {String} user_id
 */
LoginResult.prototype['user_id'] = undefined;

/**
 * @member {module:model/DefinedUserRoles} role_id
 */
LoginResult.prototype['role_id'] = undefined;

/**
 * @member {String} customer_id
 */
LoginResult.prototype['customer_id'] = undefined;

/**
 * @member {Number} contact_id
 */
LoginResult.prototype['contact_id'] = undefined;

/**
 * @member {Number} active_subscription_id
 */
LoginResult.prototype['active_subscription_id'] = undefined;

/**
 * @member {String} subscription_type_id
 */
LoginResult.prototype['subscription_type_id'] = undefined;

/**
 * @member {module:model/LoginInfo} resources
 */
LoginResult.prototype['resources'] = undefined;

/**
 * @member {Object.<String, Boolean>} permissions
 */
LoginResult.prototype['permissions'] = undefined;

/**
 * @member {Array.<module:model/Country>} countries
 */
LoginResult.prototype['countries'] = undefined;

/**
 * @member {module:model/ResultCode} code
 */
LoginResult.prototype['code'] = undefined;

/**
 * @member {Boolean} success
 */
LoginResult.prototype['success'] = undefined;
var _default = LoginResult;
exports.default = _default;