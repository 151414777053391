"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The FileFilter model module.
 * @module model/FileFilter
 * @version 1.1.3-alpha.1
 */
class FileFilter {
  /**
   * Constructs a new <code>FileFilter</code>.
   * @alias module:model/FileFilter
   */
  constructor() {
    FileFilter.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>FileFilter</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FileFilter} obj Optional instance to populate.
   * @return {module:model/FileFilter} The populated <code>FileFilter</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FileFilter();
      if (data.hasOwnProperty('ids')) {
        obj['ids'] = _ApiClient.default.convertToType(data['ids'], ['String']);
      }
      if (data.hasOwnProperty('q')) {
        obj['q'] = _ApiClient.default.convertToType(data['q'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>FileFilter</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>FileFilter</code>.
   */
  static validateJSON(data) {
    // ensure the json data is an array
    if (!Array.isArray(data['ids'])) {
      throw new Error("Expected the field `ids` to be an array in the JSON data but got " + data['ids']);
    }
    // ensure the json data is a string
    if (data['q'] && !(typeof data['q'] === 'string' || data['q'] instanceof String)) {
      throw new Error("Expected the field `q` to be a primitive type in the JSON string but got " + data['q']);
    }
    return true;
  }
}

/**
 * @member {Array.<String>} ids
 */
FileFilter.prototype['ids'] = undefined;

/**
 * @member {String} q
 */
FileFilter.prototype['q'] = undefined;
var _default = FileFilter;
exports.default = _default;