"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Attachment model module.
 * @module model/Attachment
 * @version 1.1.3-alpha.1
 */
class Attachment {
  /**
   * Constructs a new <code>Attachment</code>.
   * @alias module:model/Attachment
   * @param id {String} 
   * @param file_index_type {Number} 
   * @param name {String} 
   * @param is_link {Boolean} 
   * @param is_attached_to_media {Boolean} 
   * @param is_ready {Boolean} 
   * @param is_temp {Boolean} 
   * @param date_created {String} 
   * @param for_deletion {Boolean} 
   */
  constructor(id, file_index_type, name, is_link, is_attached_to_media, is_ready, is_temp, date_created, for_deletion) {
    Attachment.initialize(this, id, file_index_type, name, is_link, is_attached_to_media, is_ready, is_temp, date_created, for_deletion);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, file_index_type, name, is_link, is_attached_to_media, is_ready, is_temp, date_created, for_deletion) {
    obj['id'] = id;
    obj['file_index_type'] = file_index_type;
    obj['name'] = name;
    obj['is_link'] = is_link;
    obj['is_attached_to_media'] = is_attached_to_media;
    obj['is_ready'] = is_ready;
    obj['is_temp'] = is_temp;
    obj['date_created'] = date_created;
    obj['for_deletion'] = for_deletion;
  }

  /**
   * Constructs a <code>Attachment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Attachment} obj Optional instance to populate.
   * @return {module:model/Attachment} The populated <code>Attachment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Attachment();
      if (data.hasOwnProperty('file_id')) {
        obj['file_id'] = _ApiClient.default.convertToType(data['file_id'], 'String');
      }
      if (data.hasOwnProperty('identifier')) {
        obj['identifier'] = _ApiClient.default.convertToType(data['identifier'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('file_index_type')) {
        obj['file_index_type'] = _ApiClient.default.convertToType(data['file_index_type'], 'Number');
      }
      if (data.hasOwnProperty('path')) {
        obj['path'] = _ApiClient.default.convertToType(data['path'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('mime_type')) {
        obj['mime_type'] = _ApiClient.default.convertToType(data['mime_type'], 'String');
      }
      if (data.hasOwnProperty('file_url')) {
        obj['file_url'] = _ApiClient.default.convertToType(data['file_url'], 'String');
      }
      if (data.hasOwnProperty('link_type')) {
        obj['link_type'] = _ApiClient.default.convertToType(data['link_type'], 'String');
      }
      if (data.hasOwnProperty('is_link')) {
        obj['is_link'] = _ApiClient.default.convertToType(data['is_link'], 'Boolean');
      }
      if (data.hasOwnProperty('is_attached_to_media')) {
        obj['is_attached_to_media'] = _ApiClient.default.convertToType(data['is_attached_to_media'], 'Boolean');
      }
      if (data.hasOwnProperty('is_ready')) {
        obj['is_ready'] = _ApiClient.default.convertToType(data['is_ready'], 'Boolean');
      }
      if (data.hasOwnProperty('is_temp')) {
        obj['is_temp'] = _ApiClient.default.convertToType(data['is_temp'], 'Boolean');
      }
      if (data.hasOwnProperty('date_created')) {
        obj['date_created'] = _ApiClient.default.convertToType(data['date_created'], 'String');
      }
      if (data.hasOwnProperty('date_ready')) {
        obj['date_ready'] = _ApiClient.default.convertToType(data['date_ready'], 'String');
      }
      if (data.hasOwnProperty('thumbnail_type')) {
        obj['thumbnail_type'] = _ApiClient.default.convertToType(data['thumbnail_type'], 'String');
      }
      if (data.hasOwnProperty('thumbnail_parent_id')) {
        obj['thumbnail_parent_id'] = _ApiClient.default.convertToType(data['thumbnail_parent_id'], 'String');
      }
      if (data.hasOwnProperty('for_deletion')) {
        obj['for_deletion'] = _ApiClient.default.convertToType(data['for_deletion'], 'Boolean');
      }
      if (data.hasOwnProperty('deletion_attempt')) {
        obj['deletion_attempt'] = _ApiClient.default.convertToType(data['deletion_attempt'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Attachment</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Attachment</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Attachment.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['file_id'] && !(typeof data['file_id'] === 'string' || data['file_id'] instanceof String)) {
      throw new Error("Expected the field `file_id` to be a primitive type in the JSON string but got " + data['file_id']);
    }
    // ensure the json data is a string
    if (data['identifier'] && !(typeof data['identifier'] === 'string' || data['identifier'] instanceof String)) {
      throw new Error("Expected the field `identifier` to be a primitive type in the JSON string but got " + data['identifier']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['path'] && !(typeof data['path'] === 'string' || data['path'] instanceof String)) {
      throw new Error("Expected the field `path` to be a primitive type in the JSON string but got " + data['path']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['mime_type'] && !(typeof data['mime_type'] === 'string' || data['mime_type'] instanceof String)) {
      throw new Error("Expected the field `mime_type` to be a primitive type in the JSON string but got " + data['mime_type']);
    }
    // ensure the json data is a string
    if (data['file_url'] && !(typeof data['file_url'] === 'string' || data['file_url'] instanceof String)) {
      throw new Error("Expected the field `file_url` to be a primitive type in the JSON string but got " + data['file_url']);
    }
    // ensure the json data is a string
    if (data['link_type'] && !(typeof data['link_type'] === 'string' || data['link_type'] instanceof String)) {
      throw new Error("Expected the field `link_type` to be a primitive type in the JSON string but got " + data['link_type']);
    }
    // ensure the json data is a string
    if (data['thumbnail_type'] && !(typeof data['thumbnail_type'] === 'string' || data['thumbnail_type'] instanceof String)) {
      throw new Error("Expected the field `thumbnail_type` to be a primitive type in the JSON string but got " + data['thumbnail_type']);
    }
    // ensure the json data is a string
    if (data['thumbnail_parent_id'] && !(typeof data['thumbnail_parent_id'] === 'string' || data['thumbnail_parent_id'] instanceof String)) {
      throw new Error("Expected the field `thumbnail_parent_id` to be a primitive type in the JSON string but got " + data['thumbnail_parent_id']);
    }
    return true;
  }
}
Attachment.RequiredProperties = ["id", "file_index_type", "name", "is_link", "is_attached_to_media", "is_ready", "is_temp", "date_created", "for_deletion"];

/**
 * @member {String} file_id
 */
Attachment.prototype['file_id'] = undefined;

/**
 * @member {String} identifier
 */
Attachment.prototype['identifier'] = undefined;

/**
 * @member {String} id
 */
Attachment.prototype['id'] = undefined;

/**
 * @member {Number} file_index_type
 */
Attachment.prototype['file_index_type'] = undefined;

/**
 * @member {String} path
 */
Attachment.prototype['path'] = undefined;

/**
 * @member {String} name
 */
Attachment.prototype['name'] = undefined;

/**
 * @member {String} mime_type
 */
Attachment.prototype['mime_type'] = undefined;

/**
 * @member {String} file_url
 */
Attachment.prototype['file_url'] = undefined;

/**
 * @member {String} link_type
 */
Attachment.prototype['link_type'] = undefined;

/**
 * @member {Boolean} is_link
 */
Attachment.prototype['is_link'] = undefined;

/**
 * @member {Boolean} is_attached_to_media
 */
Attachment.prototype['is_attached_to_media'] = undefined;

/**
 * @member {Boolean} is_ready
 */
Attachment.prototype['is_ready'] = undefined;

/**
 * @member {Boolean} is_temp
 */
Attachment.prototype['is_temp'] = undefined;

/**
 * @member {String} date_created
 */
Attachment.prototype['date_created'] = undefined;

/**
 * @member {String} date_ready
 */
Attachment.prototype['date_ready'] = undefined;

/**
 * @member {String} thumbnail_type
 */
Attachment.prototype['thumbnail_type'] = undefined;

/**
 * @member {String} thumbnail_parent_id
 */
Attachment.prototype['thumbnail_parent_id'] = undefined;

/**
 * @member {Boolean} for_deletion
 */
Attachment.prototype['for_deletion'] = undefined;

/**
 * @member {String} deletion_attempt
 */
Attachment.prototype['deletion_attempt'] = undefined;
var _default = Attachment;
exports.default = _default;