"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _UploadItemInfo = _interopRequireDefault(require("./UploadItemInfo"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CreateBatchUploadModel model module.
 * @module model/CreateBatchUploadModel
 * @version 1.1.3-alpha.1
 */
class CreateBatchUploadModel {
  /**
   * Constructs a new <code>CreateBatchUploadModel</code>.
   * @alias module:model/CreateBatchUploadModel
   * @param filesInfo {Object.<String, module:model/UploadItemInfo>} 
   */
  constructor(filesInfo) {
    CreateBatchUploadModel.initialize(this, filesInfo);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, filesInfo) {
    obj['filesInfo'] = filesInfo;
  }

  /**
   * Constructs a <code>CreateBatchUploadModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CreateBatchUploadModel} obj Optional instance to populate.
   * @return {module:model/CreateBatchUploadModel} The populated <code>CreateBatchUploadModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateBatchUploadModel();
      if (data.hasOwnProperty('filesInfo')) {
        obj['filesInfo'] = _ApiClient.default.convertToType(data['filesInfo'], {
          'String': _UploadItemInfo.default
        });
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CreateBatchUploadModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CreateBatchUploadModel</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CreateBatchUploadModel.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    return true;
  }
}
CreateBatchUploadModel.RequiredProperties = ["filesInfo"];

/**
 * @member {Object.<String, module:model/UploadItemInfo>} filesInfo
 */
CreateBatchUploadModel.prototype['filesInfo'] = undefined;
var _default = CreateBatchUploadModel;
exports.default = _default;