"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The LogUserActionModel model module.
 * @module model/LogUserActionModel
 * @version 1.1.3-alpha.1
 */
class LogUserActionModel {
  /**
   * Constructs a new <code>LogUserActionModel</code>.
   * @alias module:model/LogUserActionModel
   * @param action {String} 
   */
  constructor(action) {
    LogUserActionModel.initialize(this, action);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, action) {
    obj['action'] = action;
  }

  /**
   * Constructs a <code>LogUserActionModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LogUserActionModel} obj Optional instance to populate.
   * @return {module:model/LogUserActionModel} The populated <code>LogUserActionModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LogUserActionModel();
      if (data.hasOwnProperty('action')) {
        obj['action'] = _ApiClient.default.convertToType(data['action'], 'String');
      }
      if (data.hasOwnProperty('masterEntityId')) {
        obj['masterEntityId'] = _ApiClient.default.convertToType(data['masterEntityId'], 'String');
      }
      if (data.hasOwnProperty('childEntity1Id')) {
        obj['childEntity1Id'] = _ApiClient.default.convertToType(data['childEntity1Id'], 'String');
      }
      if (data.hasOwnProperty('childEntity2Id')) {
        obj['childEntity2Id'] = _ApiClient.default.convertToType(data['childEntity2Id'], 'String');
      }
      if (data.hasOwnProperty('textData')) {
        obj['textData'] = _ApiClient.default.convertToType(data['textData'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>LogUserActionModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>LogUserActionModel</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of LogUserActionModel.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['action'] && !(typeof data['action'] === 'string' || data['action'] instanceof String)) {
      throw new Error("Expected the field `action` to be a primitive type in the JSON string but got " + data['action']);
    }
    // ensure the json data is a string
    if (data['masterEntityId'] && !(typeof data['masterEntityId'] === 'string' || data['masterEntityId'] instanceof String)) {
      throw new Error("Expected the field `masterEntityId` to be a primitive type in the JSON string but got " + data['masterEntityId']);
    }
    // ensure the json data is a string
    if (data['childEntity1Id'] && !(typeof data['childEntity1Id'] === 'string' || data['childEntity1Id'] instanceof String)) {
      throw new Error("Expected the field `childEntity1Id` to be a primitive type in the JSON string but got " + data['childEntity1Id']);
    }
    // ensure the json data is a string
    if (data['childEntity2Id'] && !(typeof data['childEntity2Id'] === 'string' || data['childEntity2Id'] instanceof String)) {
      throw new Error("Expected the field `childEntity2Id` to be a primitive type in the JSON string but got " + data['childEntity2Id']);
    }
    // ensure the json data is a string
    if (data['textData'] && !(typeof data['textData'] === 'string' || data['textData'] instanceof String)) {
      throw new Error("Expected the field `textData` to be a primitive type in the JSON string but got " + data['textData']);
    }
    return true;
  }
}
LogUserActionModel.RequiredProperties = ["action"];

/**
 * @member {String} action
 */
LogUserActionModel.prototype['action'] = undefined;

/**
 * @member {String} masterEntityId
 */
LogUserActionModel.prototype['masterEntityId'] = undefined;

/**
 * @member {String} childEntity1Id
 */
LogUserActionModel.prototype['childEntity1Id'] = undefined;

/**
 * @member {String} childEntity2Id
 */
LogUserActionModel.prototype['childEntity2Id'] = undefined;

/**
 * @member {String} textData
 */
LogUserActionModel.prototype['textData'] = undefined;
var _default = LogUserActionModel;
exports.default = _default;