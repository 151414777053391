"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CreateKeyTakeAwayModel model module.
 * @module model/CreateKeyTakeAwayModel
 * @version 1.1.3-alpha.1
 */
class CreateKeyTakeAwayModel {
  /**
   * Constructs a new <code>CreateKeyTakeAwayModel</code>.
   * @alias module:model/CreateKeyTakeAwayModel
   * @param media_id {String} 
   * @param caption {String} 
   */
  constructor(media_id, caption) {
    CreateKeyTakeAwayModel.initialize(this, media_id, caption);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, media_id, caption) {
    obj['media_id'] = media_id;
    obj['caption'] = caption;
  }

  /**
   * Constructs a <code>CreateKeyTakeAwayModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CreateKeyTakeAwayModel} obj Optional instance to populate.
   * @return {module:model/CreateKeyTakeAwayModel} The populated <code>CreateKeyTakeAwayModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateKeyTakeAwayModel();
      if (data.hasOwnProperty('media_id')) {
        obj['media_id'] = _ApiClient.default.convertToType(data['media_id'], 'String');
      }
      if (data.hasOwnProperty('caption')) {
        obj['caption'] = _ApiClient.default.convertToType(data['caption'], 'String');
      }
      if (data.hasOwnProperty('timeout')) {
        obj['timeout'] = _ApiClient.default.convertToType(data['timeout'], 'Number');
      }
      if (data.hasOwnProperty('ordinal')) {
        obj['ordinal'] = _ApiClient.default.convertToType(data['ordinal'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CreateKeyTakeAwayModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CreateKeyTakeAwayModel</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CreateKeyTakeAwayModel.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['media_id'] && !(typeof data['media_id'] === 'string' || data['media_id'] instanceof String)) {
      throw new Error("Expected the field `media_id` to be a primitive type in the JSON string but got " + data['media_id']);
    }
    // ensure the json data is a string
    if (data['caption'] && !(typeof data['caption'] === 'string' || data['caption'] instanceof String)) {
      throw new Error("Expected the field `caption` to be a primitive type in the JSON string but got " + data['caption']);
    }
    return true;
  }
}
CreateKeyTakeAwayModel.RequiredProperties = ["media_id", "caption"];

/**
 * @member {String} media_id
 */
CreateKeyTakeAwayModel.prototype['media_id'] = undefined;

/**
 * @member {String} caption
 */
CreateKeyTakeAwayModel.prototype['caption'] = undefined;

/**
 * @member {Number} timeout
 */
CreateKeyTakeAwayModel.prototype['timeout'] = undefined;

/**
 * @member {Number} ordinal
 */
CreateKeyTakeAwayModel.prototype['ordinal'] = undefined;
var _default = CreateKeyTakeAwayModel;
exports.default = _default;