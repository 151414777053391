"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _MailgunEventPayloadData = _interopRequireDefault(require("./MailgunEventPayloadData"));
var _MailgunEventPayloadSignature = _interopRequireDefault(require("./MailgunEventPayloadSignature"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MailgunEventPayload model module.
 * @module model/MailgunEventPayload
 * @version 1.1.3-alpha.1
 */
class MailgunEventPayload {
  /**
   * Constructs a new <code>MailgunEventPayload</code>.
   * @alias module:model/MailgunEventPayload
   * @param signature {module:model/MailgunEventPayloadSignature} 
   * @param event_data {module:model/MailgunEventPayloadData} 
   */
  constructor(signature, event_data) {
    MailgunEventPayload.initialize(this, signature, event_data);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, signature, event_data) {
    obj['signature'] = signature;
    obj['event-data'] = event_data;
  }

  /**
   * Constructs a <code>MailgunEventPayload</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MailgunEventPayload} obj Optional instance to populate.
   * @return {module:model/MailgunEventPayload} The populated <code>MailgunEventPayload</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MailgunEventPayload();
      if (data.hasOwnProperty('signature')) {
        obj['signature'] = _MailgunEventPayloadSignature.default.constructFromObject(data['signature']);
      }
      if (data.hasOwnProperty('event-data')) {
        obj['event-data'] = _MailgunEventPayloadData.default.constructFromObject(data['event-data']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MailgunEventPayload</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MailgunEventPayload</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of MailgunEventPayload.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // validate the optional field `signature`
    if (data['signature']) {
      // data not null
      _MailgunEventPayloadSignature.default.validateJSON(data['signature']);
    }
    // validate the optional field `event-data`
    if (data['event-data']) {
      // data not null
      _MailgunEventPayloadData.default.validateJSON(data['event-data']);
    }
    return true;
  }
}
MailgunEventPayload.RequiredProperties = ["signature", "event-data"];

/**
 * @member {module:model/MailgunEventPayloadSignature} signature
 */
MailgunEventPayload.prototype['signature'] = undefined;

/**
 * @member {module:model/MailgunEventPayloadData} event-data
 */
MailgunEventPayload.prototype['event-data'] = undefined;
var _default = MailgunEventPayload;
exports.default = _default;