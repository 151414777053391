"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The SortDescriptor model module.
 * @module model/SortDescriptor
 * @version 1.1.3-alpha.1
 */
class SortDescriptor {
  /**
   * Constructs a new <code>SortDescriptor</code>.
   * @alias module:model/SortDescriptor
   */
  constructor() {
    SortDescriptor.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>SortDescriptor</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SortDescriptor} obj Optional instance to populate.
   * @return {module:model/SortDescriptor} The populated <code>SortDescriptor</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SortDescriptor();
      if (data.hasOwnProperty('field')) {
        obj['field'] = _ApiClient.default.convertToType(data['field'], 'String');
      }
      if (data.hasOwnProperty('desc')) {
        obj['desc'] = _ApiClient.default.convertToType(data['desc'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>SortDescriptor</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SortDescriptor</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['field'] && !(typeof data['field'] === 'string' || data['field'] instanceof String)) {
      throw new Error("Expected the field `field` to be a primitive type in the JSON string but got " + data['field']);
    }
    return true;
  }
}

/**
 * @member {String} field
 */
SortDescriptor.prototype['field'] = undefined;

/**
 * @member {Boolean} desc
 */
SortDescriptor.prototype['desc'] = undefined;
var _default = SortDescriptor;
exports.default = _default;