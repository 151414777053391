"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CommandCreateAdScore model module.
 * @module model/CommandCreateAdScore
 * @version 1.1.3-alpha.1
 */
class CommandCreateAdScore {
  /**
   * Constructs a new <code>CommandCreateAdScore</code>.
   * @alias module:model/CommandCreateAdScore
   * @param industry_id {String} 
   * @param company_id {String} 
   * @param country_id {String} 
   * @param release_date {String} 
   * @param length {Number} 
   * @param campaign {String} 
   * @param video_link {String} 
   */
  constructor(industry_id, company_id, country_id, release_date, length, campaign, video_link) {
    CommandCreateAdScore.initialize(this, industry_id, company_id, country_id, release_date, length, campaign, video_link);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, industry_id, company_id, country_id, release_date, length, campaign, video_link) {
    obj['industry_id'] = industry_id;
    obj['company_id'] = company_id;
    obj['country_id'] = country_id;
    obj['release_date'] = release_date;
    obj['length'] = length;
    obj['campaign'] = campaign;
    obj['video_link'] = video_link;
  }

  /**
   * Constructs a <code>CommandCreateAdScore</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CommandCreateAdScore} obj Optional instance to populate.
   * @return {module:model/CommandCreateAdScore} The populated <code>CommandCreateAdScore</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CommandCreateAdScore();
      if (data.hasOwnProperty('industry_id')) {
        obj['industry_id'] = _ApiClient.default.convertToType(data['industry_id'], 'String');
      }
      if (data.hasOwnProperty('company_id')) {
        obj['company_id'] = _ApiClient.default.convertToType(data['company_id'], 'String');
      }
      if (data.hasOwnProperty('brand_id')) {
        obj['brand_id'] = _ApiClient.default.convertToType(data['brand_id'], 'String');
      }
      if (data.hasOwnProperty('country_id')) {
        obj['country_id'] = _ApiClient.default.convertToType(data['country_id'], 'String');
      }
      if (data.hasOwnProperty('available_in_country_ids')) {
        obj['available_in_country_ids'] = _ApiClient.default.convertToType(data['available_in_country_ids'], ['String']);
      }
      if (data.hasOwnProperty('release_date')) {
        obj['release_date'] = _ApiClient.default.convertToType(data['release_date'], 'String');
      }
      if (data.hasOwnProperty('length')) {
        obj['length'] = _ApiClient.default.convertToType(data['length'], 'Number');
      }
      if (data.hasOwnProperty('campaign')) {
        obj['campaign'] = _ApiClient.default.convertToType(data['campaign'], 'String');
      }
      if (data.hasOwnProperty('video_link')) {
        obj['video_link'] = _ApiClient.default.convertToType(data['video_link'], 'String');
      }
      if (data.hasOwnProperty('agency')) {
        obj['agency'] = _ApiClient.default.convertToType(data['agency'], 'String');
      }
      if (data.hasOwnProperty('thumbnail_id')) {
        obj['thumbnail_id'] = _ApiClient.default.convertToType(data['thumbnail_id'], 'String');
      }
      if (data.hasOwnProperty('tag_ids')) {
        obj['tag_ids'] = _ApiClient.default.convertToType(data['tag_ids'], ['Number']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CommandCreateAdScore</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CommandCreateAdScore</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CommandCreateAdScore.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['industry_id'] && !(typeof data['industry_id'] === 'string' || data['industry_id'] instanceof String)) {
      throw new Error("Expected the field `industry_id` to be a primitive type in the JSON string but got " + data['industry_id']);
    }
    // ensure the json data is a string
    if (data['company_id'] && !(typeof data['company_id'] === 'string' || data['company_id'] instanceof String)) {
      throw new Error("Expected the field `company_id` to be a primitive type in the JSON string but got " + data['company_id']);
    }
    // ensure the json data is a string
    if (data['brand_id'] && !(typeof data['brand_id'] === 'string' || data['brand_id'] instanceof String)) {
      throw new Error("Expected the field `brand_id` to be a primitive type in the JSON string but got " + data['brand_id']);
    }
    // ensure the json data is a string
    if (data['country_id'] && !(typeof data['country_id'] === 'string' || data['country_id'] instanceof String)) {
      throw new Error("Expected the field `country_id` to be a primitive type in the JSON string but got " + data['country_id']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['available_in_country_ids'])) {
      throw new Error("Expected the field `available_in_country_ids` to be an array in the JSON data but got " + data['available_in_country_ids']);
    }
    // ensure the json data is a string
    if (data['campaign'] && !(typeof data['campaign'] === 'string' || data['campaign'] instanceof String)) {
      throw new Error("Expected the field `campaign` to be a primitive type in the JSON string but got " + data['campaign']);
    }
    // ensure the json data is a string
    if (data['video_link'] && !(typeof data['video_link'] === 'string' || data['video_link'] instanceof String)) {
      throw new Error("Expected the field `video_link` to be a primitive type in the JSON string but got " + data['video_link']);
    }
    // ensure the json data is a string
    if (data['agency'] && !(typeof data['agency'] === 'string' || data['agency'] instanceof String)) {
      throw new Error("Expected the field `agency` to be a primitive type in the JSON string but got " + data['agency']);
    }
    // ensure the json data is a string
    if (data['thumbnail_id'] && !(typeof data['thumbnail_id'] === 'string' || data['thumbnail_id'] instanceof String)) {
      throw new Error("Expected the field `thumbnail_id` to be a primitive type in the JSON string but got " + data['thumbnail_id']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['tag_ids'])) {
      throw new Error("Expected the field `tag_ids` to be an array in the JSON data but got " + data['tag_ids']);
    }
    return true;
  }
}
CommandCreateAdScore.RequiredProperties = ["industry_id", "company_id", "country_id", "release_date", "length", "campaign", "video_link"];

/**
 * @member {String} industry_id
 */
CommandCreateAdScore.prototype['industry_id'] = undefined;

/**
 * @member {String} company_id
 */
CommandCreateAdScore.prototype['company_id'] = undefined;

/**
 * @member {String} brand_id
 */
CommandCreateAdScore.prototype['brand_id'] = undefined;

/**
 * @member {String} country_id
 */
CommandCreateAdScore.prototype['country_id'] = undefined;

/**
 * @member {Array.<String>} available_in_country_ids
 */
CommandCreateAdScore.prototype['available_in_country_ids'] = undefined;

/**
 * @member {String} release_date
 */
CommandCreateAdScore.prototype['release_date'] = undefined;

/**
 * @member {Number} length
 */
CommandCreateAdScore.prototype['length'] = undefined;

/**
 * @member {String} campaign
 */
CommandCreateAdScore.prototype['campaign'] = undefined;

/**
 * @member {String} video_link
 */
CommandCreateAdScore.prototype['video_link'] = undefined;

/**
 * @member {String} agency
 */
CommandCreateAdScore.prototype['agency'] = undefined;

/**
 * @member {String} thumbnail_id
 */
CommandCreateAdScore.prototype['thumbnail_id'] = undefined;

/**
 * @member {Array.<Number>} tag_ids
 */
CommandCreateAdScore.prototype['tag_ids'] = undefined;
var _default = CommandCreateAdScore;
exports.default = _default;