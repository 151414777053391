"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CustomerContact model module.
 * @module model/CustomerContact
 * @version 1.1.3-alpha.1
 */
class CustomerContact {
  /**
   * Constructs a new <code>CustomerContact</code>.
   * @alias module:model/CustomerContact
   * @param id {Number} 
   * @param customer_id {String} 
   * @param name {String} 
   * @param last_name {String} 
   * @param user_id {String} 
   * @param is_admin {Boolean} 
   * @param is_suspended {Boolean} 
   */
  constructor(id, customer_id, name, last_name, user_id, is_admin, is_suspended) {
    CustomerContact.initialize(this, id, customer_id, name, last_name, user_id, is_admin, is_suspended);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, customer_id, name, last_name, user_id, is_admin, is_suspended) {
    obj['id'] = id;
    obj['customer_id'] = customer_id;
    obj['name'] = name;
    obj['last_name'] = last_name;
    obj['user_id'] = user_id;
    obj['is_admin'] = is_admin;
    obj['is_suspended'] = is_suspended;
  }

  /**
   * Constructs a <code>CustomerContact</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CustomerContact} obj Optional instance to populate.
   * @return {module:model/CustomerContact} The populated <code>CustomerContact</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerContact();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('customer_id')) {
        obj['customer_id'] = _ApiClient.default.convertToType(data['customer_id'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('last_name')) {
        obj['last_name'] = _ApiClient.default.convertToType(data['last_name'], 'String');
      }
      if (data.hasOwnProperty('job_title')) {
        obj['job_title'] = _ApiClient.default.convertToType(data['job_title'], 'String');
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('is_admin')) {
        obj['is_admin'] = _ApiClient.default.convertToType(data['is_admin'], 'Boolean');
      }
      if (data.hasOwnProperty('is_suspended')) {
        obj['is_suspended'] = _ApiClient.default.convertToType(data['is_suspended'], 'Boolean');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('is_counted')) {
        obj['is_counted'] = _ApiClient.default.convertToType(data['is_counted'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CustomerContact</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CustomerContact</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CustomerContact.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['customer_id'] && !(typeof data['customer_id'] === 'string' || data['customer_id'] instanceof String)) {
      throw new Error("Expected the field `customer_id` to be a primitive type in the JSON string but got " + data['customer_id']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['last_name'] && !(typeof data['last_name'] === 'string' || data['last_name'] instanceof String)) {
      throw new Error("Expected the field `last_name` to be a primitive type in the JSON string but got " + data['last_name']);
    }
    // ensure the json data is a string
    if (data['job_title'] && !(typeof data['job_title'] === 'string' || data['job_title'] instanceof String)) {
      throw new Error("Expected the field `job_title` to be a primitive type in the JSON string but got " + data['job_title']);
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    return true;
  }
}
CustomerContact.RequiredProperties = ["id", "customer_id", "name", "last_name", "user_id", "is_admin", "is_suspended"];

/**
 * @member {Number} id
 */
CustomerContact.prototype['id'] = undefined;

/**
 * @member {String} customer_id
 */
CustomerContact.prototype['customer_id'] = undefined;

/**
 * @member {String} name
 */
CustomerContact.prototype['name'] = undefined;

/**
 * @member {String} last_name
 */
CustomerContact.prototype['last_name'] = undefined;

/**
 * @member {String} job_title
 */
CustomerContact.prototype['job_title'] = undefined;

/**
 * @member {String} user_id
 */
CustomerContact.prototype['user_id'] = undefined;

/**
 * @member {Boolean} is_admin
 */
CustomerContact.prototype['is_admin'] = undefined;

/**
 * @member {Boolean} is_suspended
 */
CustomerContact.prototype['is_suspended'] = undefined;

/**
 * @member {String} email
 */
CustomerContact.prototype['email'] = undefined;

/**
 * @member {Boolean} is_counted
 */
CustomerContact.prototype['is_counted'] = undefined;
var _default = CustomerContact;
exports.default = _default;