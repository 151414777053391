"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The GetActiveUserStatsFilteredResult model module.
 * @module model/GetActiveUserStatsFilteredResult
 * @version 1.1.3-alpha.1
 */
class GetActiveUserStatsFilteredResult {
  /**
   * Constructs a new <code>GetActiveUserStatsFilteredResult</code>.
   * @alias module:model/GetActiveUserStatsFilteredResult
   */
  constructor() {
    GetActiveUserStatsFilteredResult.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>GetActiveUserStatsFilteredResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GetActiveUserStatsFilteredResult} obj Optional instance to populate.
   * @return {module:model/GetActiveUserStatsFilteredResult} The populated <code>GetActiveUserStatsFilteredResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new GetActiveUserStatsFilteredResult();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('customer_id')) {
        obj['customer_id'] = _ApiClient.default.convertToType(data['customer_id'], 'String');
      }
      if (data.hasOwnProperty('contact_id')) {
        obj['contact_id'] = _ApiClient.default.convertToType(data['contact_id'], 'Number');
      }
      if (data.hasOwnProperty('company')) {
        obj['company'] = _ApiClient.default.convertToType(data['company'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('last_name')) {
        obj['last_name'] = _ApiClient.default.convertToType(data['last_name'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('is_admin')) {
        obj['is_admin'] = _ApiClient.default.convertToType(data['is_admin'], 'Boolean');
      }
      if (data.hasOwnProperty('is_suspended')) {
        obj['is_suspended'] = _ApiClient.default.convertToType(data['is_suspended'], 'Boolean');
      }
      if (data.hasOwnProperty('loginCount')) {
        obj['loginCount'] = _ApiClient.default.convertToType(data['loginCount'], 'Number');
      }
      if (data.hasOwnProperty('averageTime')) {
        obj['averageTime'] = _ApiClient.default.convertToType(data['averageTime'], 'Number');
      }
      if (data.hasOwnProperty('loginCountLast3Months')) {
        obj['loginCountLast3Months'] = _ApiClient.default.convertToType(data['loginCountLast3Months'], 'Number');
      }
      if (data.hasOwnProperty('averageTimeLast3Months')) {
        obj['averageTimeLast3Months'] = _ApiClient.default.convertToType(data['averageTimeLast3Months'], 'Number');
      }
      if (data.hasOwnProperty('wisdropCount')) {
        obj['wisdropCount'] = _ApiClient.default.convertToType(data['wisdropCount'], 'Number');
      }
      if (data.hasOwnProperty('fullReportCount')) {
        obj['fullReportCount'] = _ApiClient.default.convertToType(data['fullReportCount'], 'Number');
      }
      if (data.hasOwnProperty('webinarCount')) {
        obj['webinarCount'] = _ApiClient.default.convertToType(data['webinarCount'], 'Number');
      }
      if (data.hasOwnProperty('uploadCount')) {
        obj['uploadCount'] = _ApiClient.default.convertToType(data['uploadCount'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>GetActiveUserStatsFilteredResult</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetActiveUserStatsFilteredResult</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['customer_id'] && !(typeof data['customer_id'] === 'string' || data['customer_id'] instanceof String)) {
      throw new Error("Expected the field `customer_id` to be a primitive type in the JSON string but got " + data['customer_id']);
    }
    // ensure the json data is a string
    if (data['company'] && !(typeof data['company'] === 'string' || data['company'] instanceof String)) {
      throw new Error("Expected the field `company` to be a primitive type in the JSON string but got " + data['company']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['last_name'] && !(typeof data['last_name'] === 'string' || data['last_name'] instanceof String)) {
      throw new Error("Expected the field `last_name` to be a primitive type in the JSON string but got " + data['last_name']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    return true;
  }
}

/**
 * @member {String} id
 */
GetActiveUserStatsFilteredResult.prototype['id'] = undefined;

/**
 * @member {String} customer_id
 */
GetActiveUserStatsFilteredResult.prototype['customer_id'] = undefined;

/**
 * @member {Number} contact_id
 */
GetActiveUserStatsFilteredResult.prototype['contact_id'] = undefined;

/**
 * @member {String} company
 */
GetActiveUserStatsFilteredResult.prototype['company'] = undefined;

/**
 * @member {String} name
 */
GetActiveUserStatsFilteredResult.prototype['name'] = undefined;

/**
 * @member {String} last_name
 */
GetActiveUserStatsFilteredResult.prototype['last_name'] = undefined;

/**
 * @member {String} email
 */
GetActiveUserStatsFilteredResult.prototype['email'] = undefined;

/**
 * @member {Boolean} is_admin
 */
GetActiveUserStatsFilteredResult.prototype['is_admin'] = undefined;

/**
 * @member {Boolean} is_suspended
 */
GetActiveUserStatsFilteredResult.prototype['is_suspended'] = undefined;

/**
 * @member {Number} loginCount
 */
GetActiveUserStatsFilteredResult.prototype['loginCount'] = undefined;

/**
 * @member {Number} averageTime
 */
GetActiveUserStatsFilteredResult.prototype['averageTime'] = undefined;

/**
 * @member {Number} loginCountLast3Months
 */
GetActiveUserStatsFilteredResult.prototype['loginCountLast3Months'] = undefined;

/**
 * @member {Number} averageTimeLast3Months
 */
GetActiveUserStatsFilteredResult.prototype['averageTimeLast3Months'] = undefined;

/**
 * @member {Number} wisdropCount
 */
GetActiveUserStatsFilteredResult.prototype['wisdropCount'] = undefined;

/**
 * @member {Number} fullReportCount
 */
GetActiveUserStatsFilteredResult.prototype['fullReportCount'] = undefined;

/**
 * @member {Number} webinarCount
 */
GetActiveUserStatsFilteredResult.prototype['webinarCount'] = undefined;

/**
 * @member {Number} uploadCount
 */
GetActiveUserStatsFilteredResult.prototype['uploadCount'] = undefined;
var _default = GetActiveUserStatsFilteredResult;
exports.default = _default;