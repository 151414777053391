"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AdScore model module.
 * @module model/AdScore
 * @version 1.1.3-alpha.1
 */
class AdScore {
  /**
   * Constructs a new <code>AdScore</code>.
   * @alias module:model/AdScore
   * @param ranking {Number} 
   * @param industry_id {String} 
   * @param company_id {String} 
   * @param country_id {String} 
   * @param release_date {String} 
   * @param length {Number} 
   * @param campaign {String} 
   * @param video_link {String} 
   * @param id {String} 
   * @param created_at {String} 
   * @param updated_at {String} 
   */
  constructor(ranking, industry_id, company_id, country_id, release_date, length, campaign, video_link, id, created_at, updated_at) {
    AdScore.initialize(this, ranking, industry_id, company_id, country_id, release_date, length, campaign, video_link, id, created_at, updated_at);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, ranking, industry_id, company_id, country_id, release_date, length, campaign, video_link, id, created_at, updated_at) {
    obj['ranking'] = ranking;
    obj['industry_id'] = industry_id;
    obj['company_id'] = company_id;
    obj['country_id'] = country_id;
    obj['release_date'] = release_date;
    obj['length'] = length;
    obj['campaign'] = campaign;
    obj['video_link'] = video_link;
    obj['id'] = id;
    obj['created_at'] = created_at;
    obj['updated_at'] = updated_at;
  }

  /**
   * Constructs a <code>AdScore</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AdScore} obj Optional instance to populate.
   * @return {module:model/AdScore} The populated <code>AdScore</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AdScore();
      if (data.hasOwnProperty('ranking')) {
        obj['ranking'] = _ApiClient.default.convertToType(data['ranking'], 'Number');
      }
      if (data.hasOwnProperty('industry_id')) {
        obj['industry_id'] = _ApiClient.default.convertToType(data['industry_id'], 'String');
      }
      if (data.hasOwnProperty('company_id')) {
        obj['company_id'] = _ApiClient.default.convertToType(data['company_id'], 'String');
      }
      if (data.hasOwnProperty('brand_id')) {
        obj['brand_id'] = _ApiClient.default.convertToType(data['brand_id'], 'String');
      }
      if (data.hasOwnProperty('country_id')) {
        obj['country_id'] = _ApiClient.default.convertToType(data['country_id'], 'String');
      }
      if (data.hasOwnProperty('release_date')) {
        obj['release_date'] = _ApiClient.default.convertToType(data['release_date'], 'String');
      }
      if (data.hasOwnProperty('length')) {
        obj['length'] = _ApiClient.default.convertToType(data['length'], 'Number');
      }
      if (data.hasOwnProperty('score')) {
        obj['score'] = _ApiClient.default.convertToType(data['score'], 'Number');
      }
      if (data.hasOwnProperty('positioning')) {
        obj['positioning'] = _ApiClient.default.convertToType(data['positioning'], 'Number');
      }
      if (data.hasOwnProperty('campaign')) {
        obj['campaign'] = _ApiClient.default.convertToType(data['campaign'], 'String');
      }
      if (data.hasOwnProperty('video_link')) {
        obj['video_link'] = _ApiClient.default.convertToType(data['video_link'], 'String');
      }
      if (data.hasOwnProperty('agency')) {
        obj['agency'] = _ApiClient.default.convertToType(data['agency'], 'String');
      }
      if (data.hasOwnProperty('brand_sync')) {
        obj['brand_sync'] = _ApiClient.default.convertToType(data['brand_sync'], 'Number');
      }
      if (data.hasOwnProperty('activation')) {
        obj['activation'] = _ApiClient.default.convertToType(data['activation'], 'Number');
      }
      if (data.hasOwnProperty('filtered_ranking')) {
        obj['filtered_ranking'] = _ApiClient.default.convertToType(data['filtered_ranking'], 'Number');
      }
      if (data.hasOwnProperty('slide_count')) {
        obj['slide_count'] = _ApiClient.default.convertToType(data['slide_count'], 'Number');
      }
      if (data.hasOwnProperty('access_enabled')) {
        obj['access_enabled'] = _ApiClient.default.convertToType(data['access_enabled'], 'Boolean');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = _ApiClient.default.convertToType(data['created_at'], 'String');
      }
      if (data.hasOwnProperty('created_by')) {
        obj['created_by'] = _ApiClient.default.convertToType(data['created_by'], 'String');
      }
      if (data.hasOwnProperty('date_created')) {
        obj['date_created'] = _ApiClient.default.convertToType(data['date_created'], 'String');
      }
      if (data.hasOwnProperty('time_created')) {
        obj['time_created'] = _ApiClient.default.convertToType(data['time_created'], 'String');
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = _ApiClient.default.convertToType(data['updated_at'], 'String');
      }
      if (data.hasOwnProperty('updated_by')) {
        obj['updated_by'] = _ApiClient.default.convertToType(data['updated_by'], 'String');
      }
      if (data.hasOwnProperty('date_updated')) {
        obj['date_updated'] = _ApiClient.default.convertToType(data['date_updated'], 'String');
      }
      if (data.hasOwnProperty('time_updated')) {
        obj['time_updated'] = _ApiClient.default.convertToType(data['time_updated'], 'String');
      }
      if (data.hasOwnProperty('deleted_at')) {
        obj['deleted_at'] = _ApiClient.default.convertToType(data['deleted_at'], 'String');
      }
      if (data.hasOwnProperty('deleted_by')) {
        obj['deleted_by'] = _ApiClient.default.convertToType(data['deleted_by'], 'String');
      }
      if (data.hasOwnProperty('date_deleted')) {
        obj['date_deleted'] = _ApiClient.default.convertToType(data['date_deleted'], 'String');
      }
      if (data.hasOwnProperty('time_deleted')) {
        obj['time_deleted'] = _ApiClient.default.convertToType(data['time_deleted'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AdScore</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AdScore</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of AdScore.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['industry_id'] && !(typeof data['industry_id'] === 'string' || data['industry_id'] instanceof String)) {
      throw new Error("Expected the field `industry_id` to be a primitive type in the JSON string but got " + data['industry_id']);
    }
    // ensure the json data is a string
    if (data['company_id'] && !(typeof data['company_id'] === 'string' || data['company_id'] instanceof String)) {
      throw new Error("Expected the field `company_id` to be a primitive type in the JSON string but got " + data['company_id']);
    }
    // ensure the json data is a string
    if (data['brand_id'] && !(typeof data['brand_id'] === 'string' || data['brand_id'] instanceof String)) {
      throw new Error("Expected the field `brand_id` to be a primitive type in the JSON string but got " + data['brand_id']);
    }
    // ensure the json data is a string
    if (data['country_id'] && !(typeof data['country_id'] === 'string' || data['country_id'] instanceof String)) {
      throw new Error("Expected the field `country_id` to be a primitive type in the JSON string but got " + data['country_id']);
    }
    // ensure the json data is a string
    if (data['campaign'] && !(typeof data['campaign'] === 'string' || data['campaign'] instanceof String)) {
      throw new Error("Expected the field `campaign` to be a primitive type in the JSON string but got " + data['campaign']);
    }
    // ensure the json data is a string
    if (data['video_link'] && !(typeof data['video_link'] === 'string' || data['video_link'] instanceof String)) {
      throw new Error("Expected the field `video_link` to be a primitive type in the JSON string but got " + data['video_link']);
    }
    // ensure the json data is a string
    if (data['agency'] && !(typeof data['agency'] === 'string' || data['agency'] instanceof String)) {
      throw new Error("Expected the field `agency` to be a primitive type in the JSON string but got " + data['agency']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
      throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
    }
    // ensure the json data is a string
    if (data['time_created'] && !(typeof data['time_created'] === 'string' || data['time_created'] instanceof String)) {
      throw new Error("Expected the field `time_created` to be a primitive type in the JSON string but got " + data['time_created']);
    }
    // ensure the json data is a string
    if (data['updated_by'] && !(typeof data['updated_by'] === 'string' || data['updated_by'] instanceof String)) {
      throw new Error("Expected the field `updated_by` to be a primitive type in the JSON string but got " + data['updated_by']);
    }
    // ensure the json data is a string
    if (data['time_updated'] && !(typeof data['time_updated'] === 'string' || data['time_updated'] instanceof String)) {
      throw new Error("Expected the field `time_updated` to be a primitive type in the JSON string but got " + data['time_updated']);
    }
    // ensure the json data is a string
    if (data['deleted_by'] && !(typeof data['deleted_by'] === 'string' || data['deleted_by'] instanceof String)) {
      throw new Error("Expected the field `deleted_by` to be a primitive type in the JSON string but got " + data['deleted_by']);
    }
    // ensure the json data is a string
    if (data['time_deleted'] && !(typeof data['time_deleted'] === 'string' || data['time_deleted'] instanceof String)) {
      throw new Error("Expected the field `time_deleted` to be a primitive type in the JSON string but got " + data['time_deleted']);
    }
    return true;
  }
}
AdScore.RequiredProperties = ["ranking", "industry_id", "company_id", "country_id", "release_date", "length", "campaign", "video_link", "id", "created_at", "updated_at"];

/**
 * @member {Number} ranking
 */
AdScore.prototype['ranking'] = undefined;

/**
 * @member {String} industry_id
 */
AdScore.prototype['industry_id'] = undefined;

/**
 * @member {String} company_id
 */
AdScore.prototype['company_id'] = undefined;

/**
 * @member {String} brand_id
 */
AdScore.prototype['brand_id'] = undefined;

/**
 * @member {String} country_id
 */
AdScore.prototype['country_id'] = undefined;

/**
 * @member {String} release_date
 */
AdScore.prototype['release_date'] = undefined;

/**
 * @member {Number} length
 */
AdScore.prototype['length'] = undefined;

/**
 * @member {Number} score
 */
AdScore.prototype['score'] = undefined;

/**
 * @member {Number} positioning
 */
AdScore.prototype['positioning'] = undefined;

/**
 * @member {String} campaign
 */
AdScore.prototype['campaign'] = undefined;

/**
 * @member {String} video_link
 */
AdScore.prototype['video_link'] = undefined;

/**
 * @member {String} agency
 */
AdScore.prototype['agency'] = undefined;

/**
 * @member {Number} brand_sync
 */
AdScore.prototype['brand_sync'] = undefined;

/**
 * @member {Number} activation
 */
AdScore.prototype['activation'] = undefined;

/**
 * @member {Number} filtered_ranking
 */
AdScore.prototype['filtered_ranking'] = undefined;

/**
 * @member {Number} slide_count
 */
AdScore.prototype['slide_count'] = undefined;

/**
 * @member {Boolean} access_enabled
 */
AdScore.prototype['access_enabled'] = undefined;

/**
 * @member {String} id
 */
AdScore.prototype['id'] = undefined;

/**
 * @member {String} created_at
 */
AdScore.prototype['created_at'] = undefined;

/**
 * @member {String} created_by
 */
AdScore.prototype['created_by'] = undefined;

/**
 * @member {String} date_created
 */
AdScore.prototype['date_created'] = undefined;

/**
 * @member {String} time_created
 */
AdScore.prototype['time_created'] = undefined;

/**
 * @member {String} updated_at
 */
AdScore.prototype['updated_at'] = undefined;

/**
 * @member {String} updated_by
 */
AdScore.prototype['updated_by'] = undefined;

/**
 * @member {String} date_updated
 */
AdScore.prototype['date_updated'] = undefined;

/**
 * @member {String} time_updated
 */
AdScore.prototype['time_updated'] = undefined;

/**
 * @member {String} deleted_at
 */
AdScore.prototype['deleted_at'] = undefined;

/**
 * @member {String} deleted_by
 */
AdScore.prototype['deleted_by'] = undefined;

/**
 * @member {String} date_deleted
 */
AdScore.prototype['date_deleted'] = undefined;

/**
 * @member {String} time_deleted
 */
AdScore.prototype['time_deleted'] = undefined;
var _default = AdScore;
exports.default = _default;