"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _ReportViewStatistics = _interopRequireDefault(require("./ReportViewStatistics"));
var _ViewUserStats = _interopRequireDefault(require("./ViewUserStats"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ReportStatistics model module.
 * @module model/ReportStatistics
 * @version 1.1.3-alpha.1
 */
class ReportStatistics {
  /**
   * Constructs a new <code>ReportStatistics</code>.
   * @alias module:model/ReportStatistics
   */
  constructor() {
    ReportStatistics.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>ReportStatistics</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ReportStatistics} obj Optional instance to populate.
   * @return {module:model/ReportStatistics} The populated <code>ReportStatistics</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ReportStatistics();
      if (data.hasOwnProperty('penetration')) {
        obj['penetration'] = _ApiClient.default.convertToType(data['penetration'], 'Number');
      }
      if (data.hasOwnProperty('adoption_rate')) {
        obj['adoption_rate'] = _ApiClient.default.convertToType(data['adoption_rate'], 'Number');
      }
      if (data.hasOwnProperty('top_users')) {
        obj['top_users'] = _ApiClient.default.convertToType(data['top_users'], [_ViewUserStats.default]);
      }
      if (data.hasOwnProperty('top_reports')) {
        obj['top_reports'] = _ApiClient.default.convertToType(data['top_reports'], [_ReportViewStatistics.default]);
      }
      if (data.hasOwnProperty('top_wisdrops')) {
        obj['top_wisdrops'] = _ApiClient.default.convertToType(data['top_wisdrops'], [_ReportViewStatistics.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ReportStatistics</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ReportStatistics</code>.
   */
  static validateJSON(data) {
    if (data['top_users']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['top_users'])) {
        throw new Error("Expected the field `top_users` to be an array in the JSON data but got " + data['top_users']);
      }
      // validate the optional field `top_users` (array)
      for (const item of data['top_users']) {
        _ViewUserStats.default.validateJSON(item);
      }
      ;
    }
    if (data['top_reports']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['top_reports'])) {
        throw new Error("Expected the field `top_reports` to be an array in the JSON data but got " + data['top_reports']);
      }
      // validate the optional field `top_reports` (array)
      for (const item of data['top_reports']) {
        _ReportViewStatistics.default.validateJSON(item);
      }
      ;
    }
    if (data['top_wisdrops']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['top_wisdrops'])) {
        throw new Error("Expected the field `top_wisdrops` to be an array in the JSON data but got " + data['top_wisdrops']);
      }
      // validate the optional field `top_wisdrops` (array)
      for (const item of data['top_wisdrops']) {
        _ReportViewStatistics.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}

/**
 * @member {Number} penetration
 */
ReportStatistics.prototype['penetration'] = undefined;

/**
 * @member {Number} adoption_rate
 */
ReportStatistics.prototype['adoption_rate'] = undefined;

/**
 * @member {Array.<module:model/ViewUserStats>} top_users
 */
ReportStatistics.prototype['top_users'] = undefined;

/**
 * @member {Array.<module:model/ReportViewStatistics>} top_reports
 */
ReportStatistics.prototype['top_reports'] = undefined;

/**
 * @member {Array.<module:model/ReportViewStatistics>} top_wisdrops
 */
ReportStatistics.prototype['top_wisdrops'] = undefined;
var _default = ReportStatistics;
exports.default = _default;