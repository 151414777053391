"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DefinedCustomerType = _interopRequireDefault(require("./DefinedCustomerType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UpdateCustomerCommand model module.
 * @module model/UpdateCustomerCommand
 * @version 1.1.3-alpha.1
 */
class UpdateCustomerCommand {
  /**
   * Constructs a new <code>UpdateCustomerCommand</code>.
   * @alias module:model/UpdateCustomerCommand
   * @param id {String} 
   * @param report_pdf_download {Boolean} 
   */
  constructor(id, report_pdf_download) {
    UpdateCustomerCommand.initialize(this, id, report_pdf_download);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, report_pdf_download) {
    obj['id'] = id;
    obj['report_pdf_download'] = report_pdf_download;
  }

  /**
   * Constructs a <code>UpdateCustomerCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UpdateCustomerCommand} obj Optional instance to populate.
   * @return {module:model/UpdateCustomerCommand} The populated <code>UpdateCustomerCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UpdateCustomerCommand();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('last_name')) {
        obj['last_name'] = _ApiClient.default.convertToType(data['last_name'], 'String');
      }
      if (data.hasOwnProperty('password')) {
        obj['password'] = _ApiClient.default.convertToType(data['password'], 'String');
      }
      if (data.hasOwnProperty('customer_type')) {
        obj['customer_type'] = _DefinedCustomerType.default.constructFromObject(data['customer_type']);
      }
      if (data.hasOwnProperty('available_in_country_ids')) {
        obj['available_in_country_ids'] = _ApiClient.default.convertToType(data['available_in_country_ids'], ['String']);
      }
      if (data.hasOwnProperty('mobile_phone')) {
        obj['mobile_phone'] = _ApiClient.default.convertToType(data['mobile_phone'], 'String');
      }
      if (data.hasOwnProperty('afm')) {
        obj['afm'] = _ApiClient.default.convertToType(data['afm'], 'String');
      }
      if (data.hasOwnProperty('doy')) {
        obj['doy'] = _ApiClient.default.convertToType(data['doy'], 'String');
      }
      if (data.hasOwnProperty('job_description')) {
        obj['job_description'] = _ApiClient.default.convertToType(data['job_description'], 'String');
      }
      if (data.hasOwnProperty('is_counted')) {
        obj['is_counted'] = _ApiClient.default.convertToType(data['is_counted'], 'Boolean');
      }
      if (data.hasOwnProperty('auto_renewal')) {
        obj['auto_renewal'] = _ApiClient.default.convertToType(data['auto_renewal'], 'Boolean');
      }
      if (data.hasOwnProperty('email_domains')) {
        obj['email_domains'] = _ApiClient.default.convertToType(data['email_domains'], 'String');
      }
      if (data.hasOwnProperty('email_whitelist')) {
        obj['email_whitelist'] = _ApiClient.default.convertToType(data['email_whitelist'], 'String');
      }
      if (data.hasOwnProperty('logo_id')) {
        obj['logo_id'] = _ApiClient.default.convertToType(data['logo_id'], 'String');
      }
      if (data.hasOwnProperty('report_pdf_download')) {
        obj['report_pdf_download'] = _ApiClient.default.convertToType(data['report_pdf_download'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UpdateCustomerCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UpdateCustomerCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of UpdateCustomerCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['last_name'] && !(typeof data['last_name'] === 'string' || data['last_name'] instanceof String)) {
      throw new Error("Expected the field `last_name` to be a primitive type in the JSON string but got " + data['last_name']);
    }
    // ensure the json data is a string
    if (data['password'] && !(typeof data['password'] === 'string' || data['password'] instanceof String)) {
      throw new Error("Expected the field `password` to be a primitive type in the JSON string but got " + data['password']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['available_in_country_ids'])) {
      throw new Error("Expected the field `available_in_country_ids` to be an array in the JSON data but got " + data['available_in_country_ids']);
    }
    // ensure the json data is a string
    if (data['mobile_phone'] && !(typeof data['mobile_phone'] === 'string' || data['mobile_phone'] instanceof String)) {
      throw new Error("Expected the field `mobile_phone` to be a primitive type in the JSON string but got " + data['mobile_phone']);
    }
    // ensure the json data is a string
    if (data['afm'] && !(typeof data['afm'] === 'string' || data['afm'] instanceof String)) {
      throw new Error("Expected the field `afm` to be a primitive type in the JSON string but got " + data['afm']);
    }
    // ensure the json data is a string
    if (data['doy'] && !(typeof data['doy'] === 'string' || data['doy'] instanceof String)) {
      throw new Error("Expected the field `doy` to be a primitive type in the JSON string but got " + data['doy']);
    }
    // ensure the json data is a string
    if (data['job_description'] && !(typeof data['job_description'] === 'string' || data['job_description'] instanceof String)) {
      throw new Error("Expected the field `job_description` to be a primitive type in the JSON string but got " + data['job_description']);
    }
    // ensure the json data is a string
    if (data['email_domains'] && !(typeof data['email_domains'] === 'string' || data['email_domains'] instanceof String)) {
      throw new Error("Expected the field `email_domains` to be a primitive type in the JSON string but got " + data['email_domains']);
    }
    // ensure the json data is a string
    if (data['email_whitelist'] && !(typeof data['email_whitelist'] === 'string' || data['email_whitelist'] instanceof String)) {
      throw new Error("Expected the field `email_whitelist` to be a primitive type in the JSON string but got " + data['email_whitelist']);
    }
    // ensure the json data is a string
    if (data['logo_id'] && !(typeof data['logo_id'] === 'string' || data['logo_id'] instanceof String)) {
      throw new Error("Expected the field `logo_id` to be a primitive type in the JSON string but got " + data['logo_id']);
    }
    return true;
  }
}
UpdateCustomerCommand.RequiredProperties = ["id", "report_pdf_download"];

/**
 * @member {String} id
 */
UpdateCustomerCommand.prototype['id'] = undefined;

/**
 * @member {String} name
 */
UpdateCustomerCommand.prototype['name'] = undefined;

/**
 * @member {String} last_name
 */
UpdateCustomerCommand.prototype['last_name'] = undefined;

/**
 * @member {String} password
 */
UpdateCustomerCommand.prototype['password'] = undefined;

/**
 * @member {module:model/DefinedCustomerType} customer_type
 */
UpdateCustomerCommand.prototype['customer_type'] = undefined;

/**
 * @member {Array.<String>} available_in_country_ids
 */
UpdateCustomerCommand.prototype['available_in_country_ids'] = undefined;

/**
 * @member {String} mobile_phone
 */
UpdateCustomerCommand.prototype['mobile_phone'] = undefined;

/**
 * @member {String} afm
 */
UpdateCustomerCommand.prototype['afm'] = undefined;

/**
 * @member {String} doy
 */
UpdateCustomerCommand.prototype['doy'] = undefined;

/**
 * @member {String} job_description
 */
UpdateCustomerCommand.prototype['job_description'] = undefined;

/**
 * @member {Boolean} is_counted
 */
UpdateCustomerCommand.prototype['is_counted'] = undefined;

/**
 * @member {Boolean} auto_renewal
 */
UpdateCustomerCommand.prototype['auto_renewal'] = undefined;

/**
 * @member {String} email_domains
 */
UpdateCustomerCommand.prototype['email_domains'] = undefined;

/**
 * @member {String} email_whitelist
 */
UpdateCustomerCommand.prototype['email_whitelist'] = undefined;

/**
 * @member {String} logo_id
 */
UpdateCustomerCommand.prototype['logo_id'] = undefined;

/**
 * @member {Boolean} report_pdf_download
 */
UpdateCustomerCommand.prototype['report_pdf_download'] = undefined;
var _default = UpdateCustomerCommand;
exports.default = _default;