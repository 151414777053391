"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MailgunMessageInfo model module.
 * @module model/MailgunMessageInfo
 * @version 1.1.3-alpha.1
 */
class MailgunMessageInfo {
  /**
   * Constructs a new <code>MailgunMessageInfo</code>.
   * @alias module:model/MailgunMessageInfo
   */
  constructor() {
    MailgunMessageInfo.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MailgunMessageInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MailgunMessageInfo} obj Optional instance to populate.
   * @return {module:model/MailgunMessageInfo} The populated <code>MailgunMessageInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MailgunMessageInfo();
      if (data.hasOwnProperty('headers')) {
        obj['headers'] = _ApiClient.default.convertToType(data['headers'], {
          'String': 'String'
        });
      }
      if (data.hasOwnProperty('recipients')) {
        obj['recipients'] = _ApiClient.default.convertToType(data['recipients'], ['String']);
      }
      if (data.hasOwnProperty('size')) {
        obj['size'] = _ApiClient.default.convertToType(data['size'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MailgunMessageInfo</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MailgunMessageInfo</code>.
   */
  static validateJSON(data) {
    // ensure the json data is an array
    if (!Array.isArray(data['recipients'])) {
      throw new Error("Expected the field `recipients` to be an array in the JSON data but got " + data['recipients']);
    }
    return true;
  }
}

/**
 * @member {Object.<String, String>} headers
 */
MailgunMessageInfo.prototype['headers'] = undefined;

/**
 * @member {Array.<String>} recipients
 */
MailgunMessageInfo.prototype['recipients'] = undefined;

/**
 * @member {Number} size
 */
MailgunMessageInfo.prototype['size'] = undefined;
var _default = MailgunMessageInfo;
exports.default = _default;