"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _ResultCode = _interopRequireDefault(require("./ResultCode"));
var _ViewClientUserInfo = _interopRequireDefault(require("./ViewClientUserInfo"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The TwoStepRequiredResult model module.
 * @module model/TwoStepRequiredResult
 * @version 1.1.3-alpha.1
 */
class TwoStepRequiredResult {
  /**
   * Constructs a new <code>TwoStepRequiredResult</code>.
   * @alias module:model/TwoStepRequiredResult
   */
  constructor() {
    TwoStepRequiredResult.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>TwoStepRequiredResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TwoStepRequiredResult} obj Optional instance to populate.
   * @return {module:model/TwoStepRequiredResult} The populated <code>TwoStepRequiredResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TwoStepRequiredResult();
      if (data.hasOwnProperty('code')) {
        obj['code'] = _ResultCode.default.constructFromObject(data['code']);
      }
      if (data.hasOwnProperty('client_user_info')) {
        obj['client_user_info'] = _ApiClient.default.convertToType(data['client_user_info'], [_ViewClientUserInfo.default]);
      }
      if (data.hasOwnProperty('success')) {
        obj['success'] = _ApiClient.default.convertToType(data['success'], 'Boolean');
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = _ApiClient.default.convertToType(data['message'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>TwoStepRequiredResult</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>TwoStepRequiredResult</code>.
   */
  static validateJSON(data) {
    if (data['client_user_info']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['client_user_info'])) {
        throw new Error("Expected the field `client_user_info` to be an array in the JSON data but got " + data['client_user_info']);
      }
      // validate the optional field `client_user_info` (array)
      for (const item of data['client_user_info']) {
        _ViewClientUserInfo.default.validateJSON(item);
      }
      ;
    }
    // ensure the json data is a string
    if (data['message'] && !(typeof data['message'] === 'string' || data['message'] instanceof String)) {
      throw new Error("Expected the field `message` to be a primitive type in the JSON string but got " + data['message']);
    }
    return true;
  }
}

/**
 * @member {module:model/ResultCode} code
 */
TwoStepRequiredResult.prototype['code'] = undefined;

/**
 * @member {Array.<module:model/ViewClientUserInfo>} client_user_info
 */
TwoStepRequiredResult.prototype['client_user_info'] = undefined;

/**
 * @member {Boolean} success
 */
TwoStepRequiredResult.prototype['success'] = undefined;

/**
 * @member {String} message
 */
TwoStepRequiredResult.prototype['message'] = undefined;
var _default = TwoStepRequiredResult;
exports.default = _default;