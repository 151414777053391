"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _MailgunBatchInfo = _interopRequireDefault(require("./MailgunBatchInfo"));
var _MailgunClientInfo = _interopRequireDefault(require("./MailgunClientInfo"));
var _MailgunDeliveryStatusInfo = _interopRequireDefault(require("./MailgunDeliveryStatusInfo"));
var _MailgunEnvelopeInfo = _interopRequireDefault(require("./MailgunEnvelopeInfo"));
var _MailgunGeolocation = _interopRequireDefault(require("./MailgunGeolocation"));
var _MailgunMessageInfo = _interopRequireDefault(require("./MailgunMessageInfo"));
var _MailgunRejectInfo = _interopRequireDefault(require("./MailgunRejectInfo"));
var _MailgunStorageInfo = _interopRequireDefault(require("./MailgunStorageInfo"));
var _MailgunTemplateInfo = _interopRequireDefault(require("./MailgunTemplateInfo"));
var _MailgunUserVariablesInfo = _interopRequireDefault(require("./MailgunUserVariablesInfo"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MailgunEventPayloadData model module.
 * @module model/MailgunEventPayloadData
 * @version 1.1.3-alpha.1
 */
class MailgunEventPayloadData {
  /**
   * Constructs a new <code>MailgunEventPayloadData</code>.
   * @alias module:model/MailgunEventPayloadData
   * @param event {String} 
   * @param timestamp {Number} 
   * @param id {String} 
   */
  constructor(event, timestamp, id) {
    MailgunEventPayloadData.initialize(this, event, timestamp, id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, event, timestamp, id) {
    obj['event'] = event;
    obj['timestamp'] = timestamp;
    obj['id'] = id;
  }

  /**
   * Constructs a <code>MailgunEventPayloadData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MailgunEventPayloadData} obj Optional instance to populate.
   * @return {module:model/MailgunEventPayloadData} The populated <code>MailgunEventPayloadData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MailgunEventPayloadData();
      if (data.hasOwnProperty('event')) {
        obj['event'] = _ApiClient.default.convertToType(data['event'], 'String');
      }
      if (data.hasOwnProperty('timestamp')) {
        obj['timestamp'] = _ApiClient.default.convertToType(data['timestamp'], 'Number');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('log-level')) {
        obj['log-level'] = _ApiClient.default.convertToType(data['log-level'], 'String');
      }
      if (data.hasOwnProperty('reason')) {
        obj['reason'] = _ApiClient.default.convertToType(data['reason'], 'String');
      }
      if (data.hasOwnProperty('recipient')) {
        obj['recipient'] = _ApiClient.default.convertToType(data['recipient'], 'String');
      }
      if (data.hasOwnProperty('recipient-domain')) {
        obj['recipient-domain'] = _ApiClient.default.convertToType(data['recipient-domain'], 'String');
      }
      if (data.hasOwnProperty('severity')) {
        obj['severity'] = _ApiClient.default.convertToType(data['severity'], 'String');
      }
      if (data.hasOwnProperty('url')) {
        obj['url'] = _ApiClient.default.convertToType(data['url'], 'String');
      }
      if (data.hasOwnProperty('geolocation')) {
        obj['geolocation'] = _MailgunGeolocation.default.constructFromObject(data['geolocation']);
      }
      if (data.hasOwnProperty('tags')) {
        obj['tags'] = _ApiClient.default.convertToType(data['tags'], ['String']);
      }
      if (data.hasOwnProperty('campaigns')) {
        obj['campaigns'] = _ApiClient.default.convertToType(data['campaigns'], ['String']);
      }
      if (data.hasOwnProperty('storage')) {
        obj['storage'] = _MailgunStorageInfo.default.constructFromObject(data['storage']);
      }
      if (data.hasOwnProperty('delivery-status')) {
        obj['delivery-status'] = _MailgunDeliveryStatusInfo.default.constructFromObject(data['delivery-status']);
      }
      if (data.hasOwnProperty('template')) {
        obj['template'] = _MailgunTemplateInfo.default.constructFromObject(data['template']);
      }
      if (data.hasOwnProperty('batch')) {
        obj['batch'] = _MailgunBatchInfo.default.constructFromObject(data['batch']);
      }
      if (data.hasOwnProperty('user-variables')) {
        obj['user-variables'] = _MailgunUserVariablesInfo.default.constructFromObject(data['user-variables']);
      }
      if (data.hasOwnProperty('flags')) {
        obj['flags'] = _ApiClient.default.convertToType(data['flags'], {
          'String': 'Boolean'
        });
      }
      if (data.hasOwnProperty('ip')) {
        obj['ip'] = _ApiClient.default.convertToType(data['ip'], 'String');
      }
      if (data.hasOwnProperty('client-info')) {
        obj['client-info'] = _MailgunClientInfo.default.constructFromObject(data['client-info']);
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = _MailgunMessageInfo.default.constructFromObject(data['message']);
      }
      if (data.hasOwnProperty('envelope')) {
        obj['envelope'] = _MailgunEnvelopeInfo.default.constructFromObject(data['envelope']);
      }
      if (data.hasOwnProperty('reject')) {
        obj['reject'] = _MailgunRejectInfo.default.constructFromObject(data['reject']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MailgunEventPayloadData</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MailgunEventPayloadData</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of MailgunEventPayloadData.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['event'] && !(typeof data['event'] === 'string' || data['event'] instanceof String)) {
      throw new Error("Expected the field `event` to be a primitive type in the JSON string but got " + data['event']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['log-level'] && !(typeof data['log-level'] === 'string' || data['log-level'] instanceof String)) {
      throw new Error("Expected the field `log-level` to be a primitive type in the JSON string but got " + data['log-level']);
    }
    // ensure the json data is a string
    if (data['reason'] && !(typeof data['reason'] === 'string' || data['reason'] instanceof String)) {
      throw new Error("Expected the field `reason` to be a primitive type in the JSON string but got " + data['reason']);
    }
    // ensure the json data is a string
    if (data['recipient'] && !(typeof data['recipient'] === 'string' || data['recipient'] instanceof String)) {
      throw new Error("Expected the field `recipient` to be a primitive type in the JSON string but got " + data['recipient']);
    }
    // ensure the json data is a string
    if (data['recipient-domain'] && !(typeof data['recipient-domain'] === 'string' || data['recipient-domain'] instanceof String)) {
      throw new Error("Expected the field `recipient-domain` to be a primitive type in the JSON string but got " + data['recipient-domain']);
    }
    // ensure the json data is a string
    if (data['severity'] && !(typeof data['severity'] === 'string' || data['severity'] instanceof String)) {
      throw new Error("Expected the field `severity` to be a primitive type in the JSON string but got " + data['severity']);
    }
    // ensure the json data is a string
    if (data['url'] && !(typeof data['url'] === 'string' || data['url'] instanceof String)) {
      throw new Error("Expected the field `url` to be a primitive type in the JSON string but got " + data['url']);
    }
    // validate the optional field `geolocation`
    if (data['geolocation']) {
      // data not null
      _MailgunGeolocation.default.validateJSON(data['geolocation']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['tags'])) {
      throw new Error("Expected the field `tags` to be an array in the JSON data but got " + data['tags']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['campaigns'])) {
      throw new Error("Expected the field `campaigns` to be an array in the JSON data but got " + data['campaigns']);
    }
    // validate the optional field `storage`
    if (data['storage']) {
      // data not null
      _MailgunStorageInfo.default.validateJSON(data['storage']);
    }
    // validate the optional field `delivery-status`
    if (data['delivery-status']) {
      // data not null
      _MailgunDeliveryStatusInfo.default.validateJSON(data['delivery-status']);
    }
    // validate the optional field `template`
    if (data['template']) {
      // data not null
      _MailgunTemplateInfo.default.validateJSON(data['template']);
    }
    // validate the optional field `batch`
    if (data['batch']) {
      // data not null
      _MailgunBatchInfo.default.validateJSON(data['batch']);
    }
    // ensure the json data is a string
    if (data['ip'] && !(typeof data['ip'] === 'string' || data['ip'] instanceof String)) {
      throw new Error("Expected the field `ip` to be a primitive type in the JSON string but got " + data['ip']);
    }
    // validate the optional field `client-info`
    if (data['client-info']) {
      // data not null
      _MailgunClientInfo.default.validateJSON(data['client-info']);
    }
    // validate the optional field `message`
    if (data['message']) {
      // data not null
      _MailgunMessageInfo.default.validateJSON(data['message']);
    }
    // validate the optional field `envelope`
    if (data['envelope']) {
      // data not null
      _MailgunEnvelopeInfo.default.validateJSON(data['envelope']);
    }
    // validate the optional field `reject`
    if (data['reject']) {
      // data not null
      _MailgunRejectInfo.default.validateJSON(data['reject']);
    }
    return true;
  }
}
MailgunEventPayloadData.RequiredProperties = ["event", "timestamp", "id"];

/**
 * @member {String} event
 */
MailgunEventPayloadData.prototype['event'] = undefined;

/**
 * @member {Number} timestamp
 */
MailgunEventPayloadData.prototype['timestamp'] = undefined;

/**
 * @member {String} id
 */
MailgunEventPayloadData.prototype['id'] = undefined;

/**
 * @member {String} log-level
 */
MailgunEventPayloadData.prototype['log-level'] = undefined;

/**
 * @member {String} reason
 */
MailgunEventPayloadData.prototype['reason'] = undefined;

/**
 * @member {String} recipient
 */
MailgunEventPayloadData.prototype['recipient'] = undefined;

/**
 * @member {String} recipient-domain
 */
MailgunEventPayloadData.prototype['recipient-domain'] = undefined;

/**
 * @member {String} severity
 */
MailgunEventPayloadData.prototype['severity'] = undefined;

/**
 * @member {String} url
 */
MailgunEventPayloadData.prototype['url'] = undefined;

/**
 * @member {module:model/MailgunGeolocation} geolocation
 */
MailgunEventPayloadData.prototype['geolocation'] = undefined;

/**
 * @member {Array.<String>} tags
 */
MailgunEventPayloadData.prototype['tags'] = undefined;

/**
 * @member {Array.<String>} campaigns
 */
MailgunEventPayloadData.prototype['campaigns'] = undefined;

/**
 * @member {module:model/MailgunStorageInfo} storage
 */
MailgunEventPayloadData.prototype['storage'] = undefined;

/**
 * @member {module:model/MailgunDeliveryStatusInfo} delivery-status
 */
MailgunEventPayloadData.prototype['delivery-status'] = undefined;

/**
 * @member {module:model/MailgunTemplateInfo} template
 */
MailgunEventPayloadData.prototype['template'] = undefined;

/**
 * @member {module:model/MailgunBatchInfo} batch
 */
MailgunEventPayloadData.prototype['batch'] = undefined;

/**
 * @member {module:model/MailgunUserVariablesInfo} user-variables
 */
MailgunEventPayloadData.prototype['user-variables'] = undefined;

/**
 * @member {Object.<String, Boolean>} flags
 */
MailgunEventPayloadData.prototype['flags'] = undefined;

/**
 * @member {String} ip
 */
MailgunEventPayloadData.prototype['ip'] = undefined;

/**
 * @member {module:model/MailgunClientInfo} client-info
 */
MailgunEventPayloadData.prototype['client-info'] = undefined;

/**
 * @member {module:model/MailgunMessageInfo} message
 */
MailgunEventPayloadData.prototype['message'] = undefined;

/**
 * @member {module:model/MailgunEnvelopeInfo} envelope
 */
MailgunEventPayloadData.prototype['envelope'] = undefined;

/**
 * @member {module:model/MailgunRejectInfo} reject
 */
MailgunEventPayloadData.prototype['reject'] = undefined;
var _default = MailgunEventPayloadData;
exports.default = _default;