"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DefinedUserRoles = _interopRequireDefault(require("./DefinedUserRoles"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The User model module.
 * @module model/User
 * @version 1.1.3-alpha.1
 */
class User {
  /**
   * Constructs a new <code>User</code>.
   * @alias module:model/User
   * @param email {String} 
   * @param role_id {module:model/DefinedUserRoles} 
   * @param is_activated {Boolean} 
   * @param is_counted {Boolean} 
   * @param id {String} 
   * @param created_at {String} 
   * @param updated_at {String} 
   */
  constructor(email, role_id, is_activated, is_counted, id, created_at, updated_at) {
    User.initialize(this, email, role_id, is_activated, is_counted, id, created_at, updated_at);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, email, role_id, is_activated, is_counted, id, created_at, updated_at) {
    obj['email'] = email;
    obj['role_id'] = role_id;
    obj['is_activated'] = is_activated;
    obj['is_counted'] = is_counted;
    obj['id'] = id;
    obj['created_at'] = created_at;
    obj['updated_at'] = updated_at;
  }

  /**
   * Constructs a <code>User</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/User} obj Optional instance to populate.
   * @return {module:model/User} The populated <code>User</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new User();
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('role_id')) {
        obj['role_id'] = _DefinedUserRoles.default.constructFromObject(data['role_id']);
      }
      if (data.hasOwnProperty('is_activated')) {
        obj['is_activated'] = _ApiClient.default.convertToType(data['is_activated'], 'Boolean');
      }
      if (data.hasOwnProperty('is_counted')) {
        obj['is_counted'] = _ApiClient.default.convertToType(data['is_counted'], 'Boolean');
      }
      if (data.hasOwnProperty('activation_timeout')) {
        obj['activation_timeout'] = _ApiClient.default.convertToType(data['activation_timeout'], 'String');
      }
      if (data.hasOwnProperty('reset_password_timeout')) {
        obj['reset_password_timeout'] = _ApiClient.default.convertToType(data['reset_password_timeout'], 'String');
      }
      if (data.hasOwnProperty('direct_auth')) {
        obj['direct_auth'] = _ApiClient.default.convertToType(data['direct_auth'], 'Boolean');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = _ApiClient.default.convertToType(data['created_at'], 'String');
      }
      if (data.hasOwnProperty('created_by')) {
        obj['created_by'] = _ApiClient.default.convertToType(data['created_by'], 'String');
      }
      if (data.hasOwnProperty('date_created')) {
        obj['date_created'] = _ApiClient.default.convertToType(data['date_created'], 'String');
      }
      if (data.hasOwnProperty('time_created')) {
        obj['time_created'] = _ApiClient.default.convertToType(data['time_created'], 'String');
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = _ApiClient.default.convertToType(data['updated_at'], 'String');
      }
      if (data.hasOwnProperty('updated_by')) {
        obj['updated_by'] = _ApiClient.default.convertToType(data['updated_by'], 'String');
      }
      if (data.hasOwnProperty('date_updated')) {
        obj['date_updated'] = _ApiClient.default.convertToType(data['date_updated'], 'String');
      }
      if (data.hasOwnProperty('time_updated')) {
        obj['time_updated'] = _ApiClient.default.convertToType(data['time_updated'], 'String');
      }
      if (data.hasOwnProperty('deleted_at')) {
        obj['deleted_at'] = _ApiClient.default.convertToType(data['deleted_at'], 'String');
      }
      if (data.hasOwnProperty('deleted_by')) {
        obj['deleted_by'] = _ApiClient.default.convertToType(data['deleted_by'], 'String');
      }
      if (data.hasOwnProperty('date_deleted')) {
        obj['date_deleted'] = _ApiClient.default.convertToType(data['date_deleted'], 'String');
      }
      if (data.hasOwnProperty('time_deleted')) {
        obj['time_deleted'] = _ApiClient.default.convertToType(data['time_deleted'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>User</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>User</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of User.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
      throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
    }
    // ensure the json data is a string
    if (data['time_created'] && !(typeof data['time_created'] === 'string' || data['time_created'] instanceof String)) {
      throw new Error("Expected the field `time_created` to be a primitive type in the JSON string but got " + data['time_created']);
    }
    // ensure the json data is a string
    if (data['updated_by'] && !(typeof data['updated_by'] === 'string' || data['updated_by'] instanceof String)) {
      throw new Error("Expected the field `updated_by` to be a primitive type in the JSON string but got " + data['updated_by']);
    }
    // ensure the json data is a string
    if (data['time_updated'] && !(typeof data['time_updated'] === 'string' || data['time_updated'] instanceof String)) {
      throw new Error("Expected the field `time_updated` to be a primitive type in the JSON string but got " + data['time_updated']);
    }
    // ensure the json data is a string
    if (data['deleted_by'] && !(typeof data['deleted_by'] === 'string' || data['deleted_by'] instanceof String)) {
      throw new Error("Expected the field `deleted_by` to be a primitive type in the JSON string but got " + data['deleted_by']);
    }
    // ensure the json data is a string
    if (data['time_deleted'] && !(typeof data['time_deleted'] === 'string' || data['time_deleted'] instanceof String)) {
      throw new Error("Expected the field `time_deleted` to be a primitive type in the JSON string but got " + data['time_deleted']);
    }
    return true;
  }
}
User.RequiredProperties = ["email", "role_id", "is_activated", "is_counted", "id", "created_at", "updated_at"];

/**
 * @member {String} email
 */
User.prototype['email'] = undefined;

/**
 * @member {module:model/DefinedUserRoles} role_id
 */
User.prototype['role_id'] = undefined;

/**
 * @member {Boolean} is_activated
 */
User.prototype['is_activated'] = undefined;

/**
 * @member {Boolean} is_counted
 */
User.prototype['is_counted'] = undefined;

/**
 * @member {String} activation_timeout
 */
User.prototype['activation_timeout'] = undefined;

/**
 * @member {String} reset_password_timeout
 */
User.prototype['reset_password_timeout'] = undefined;

/**
 * @member {Boolean} direct_auth
 */
User.prototype['direct_auth'] = undefined;

/**
 * @member {String} id
 */
User.prototype['id'] = undefined;

/**
 * @member {String} created_at
 */
User.prototype['created_at'] = undefined;

/**
 * @member {String} created_by
 */
User.prototype['created_by'] = undefined;

/**
 * @member {String} date_created
 */
User.prototype['date_created'] = undefined;

/**
 * @member {String} time_created
 */
User.prototype['time_created'] = undefined;

/**
 * @member {String} updated_at
 */
User.prototype['updated_at'] = undefined;

/**
 * @member {String} updated_by
 */
User.prototype['updated_by'] = undefined;

/**
 * @member {String} date_updated
 */
User.prototype['date_updated'] = undefined;

/**
 * @member {String} time_updated
 */
User.prototype['time_updated'] = undefined;

/**
 * @member {String} deleted_at
 */
User.prototype['deleted_at'] = undefined;

/**
 * @member {String} deleted_by
 */
User.prototype['deleted_by'] = undefined;

/**
 * @member {String} date_deleted
 */
User.prototype['date_deleted'] = undefined;

/**
 * @member {String} time_deleted
 */
User.prototype['time_deleted'] = undefined;
var _default = User;
exports.default = _default;