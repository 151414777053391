"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _LoginResult = _interopRequireDefault(require("./LoginResult"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The PasswordActivateResult model module.
 * @module model/PasswordActivateResult
 * @version 1.1.3-alpha.1
 */
class PasswordActivateResult {
  /**
   * Constructs a new <code>PasswordActivateResult</code>.
   * @alias module:model/PasswordActivateResult
   */
  constructor() {
    PasswordActivateResult.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>PasswordActivateResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PasswordActivateResult} obj Optional instance to populate.
   * @return {module:model/PasswordActivateResult} The populated <code>PasswordActivateResult</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PasswordActivateResult();
      if (data.hasOwnProperty('success')) {
        obj['success'] = _ApiClient.default.convertToType(data['success'], 'Boolean');
      }
      if (data.hasOwnProperty('invalid_token')) {
        obj['invalid_token'] = _ApiClient.default.convertToType(data['invalid_token'], 'Boolean');
      }
      if (data.hasOwnProperty('expired')) {
        obj['expired'] = _ApiClient.default.convertToType(data['expired'], 'Boolean');
      }
      if (data.hasOwnProperty('passwordConfirmMismatch')) {
        obj['passwordConfirmMismatch'] = _ApiClient.default.convertToType(data['passwordConfirmMismatch'], 'Boolean');
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('loginResult')) {
        obj['loginResult'] = _LoginResult.default.constructFromObject(data['loginResult']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>PasswordActivateResult</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PasswordActivateResult</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // validate the optional field `loginResult`
    if (data['loginResult']) {
      // data not null
      _LoginResult.default.validateJSON(data['loginResult']);
    }
    return true;
  }
}

/**
 * @member {Boolean} success
 */
PasswordActivateResult.prototype['success'] = undefined;

/**
 * @member {Boolean} invalid_token
 */
PasswordActivateResult.prototype['invalid_token'] = undefined;

/**
 * @member {Boolean} expired
 */
PasswordActivateResult.prototype['expired'] = undefined;

/**
 * @member {Boolean} passwordConfirmMismatch
 */
PasswordActivateResult.prototype['passwordConfirmMismatch'] = undefined;

/**
 * @member {String} user_id
 */
PasswordActivateResult.prototype['user_id'] = undefined;

/**
 * @member {module:model/LoginResult} loginResult
 */
PasswordActivateResult.prototype['loginResult'] = undefined;
var _default = PasswordActivateResult;
exports.default = _default;