"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MailgunBatchInfo model module.
 * @module model/MailgunBatchInfo
 * @version 1.1.3-alpha.1
 */
class MailgunBatchInfo {
  /**
   * Constructs a new <code>MailgunBatchInfo</code>.
   * @alias module:model/MailgunBatchInfo
   */
  constructor() {
    MailgunBatchInfo.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MailgunBatchInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MailgunBatchInfo} obj Optional instance to populate.
   * @return {module:model/MailgunBatchInfo} The populated <code>MailgunBatchInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MailgunBatchInfo();
      if (data.hasOwnProperty('env')) {
        obj['env'] = _ApiClient.default.convertToType(data['env'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MailgunBatchInfo</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MailgunBatchInfo</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['env'] && !(typeof data['env'] === 'string' || data['env'] instanceof String)) {
      throw new Error("Expected the field `env` to be a primitive type in the JSON string but got " + data['env']);
    }
    return true;
  }
}

/**
 * @member {String} env
 */
MailgunBatchInfo.prototype['env'] = undefined;
var _default = MailgunBatchInfo;
exports.default = _default;