"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _DefinedMediaType = _interopRequireDefault(require("./DefinedMediaType"));
var _FontTypes = _interopRequireDefault(require("./FontTypes"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The Media model module.
 * @module model/Media
 * @version 1.1.3-alpha.1
 */
class Media {
  /**
   * Constructs a new <code>Media</code>.
   * @alias module:model/Media
   * @param media_type {module:model/DefinedMediaType} 
   * @param title {String} 
   * @param allow_comments {Boolean} 
   * @param font_type {module:model/FontTypes} 
   * @param media_image_version {Number} 
   * @param media_banner_version {Number} 
   * @param logo_image_version {Number} 
   * @param background_image_version {Number} 
   * @param email_image_version {Number} 
   * @param key_take_away_title {String} 
   * @param key_take_away_timeout {Number} 
   * @param season_number {Number} 
   * @param slide_count {Number} 
   * @param topline_count {Number} 
   * @param season_count {Number} 
   * @param exclude_in_new_releases {Boolean} 
   * @param send_newsletter {Boolean} 
   * @param id {String} 
   * @param created_at {String} 
   * @param updated_at {String} 
   */
  constructor(media_type, title, allow_comments, font_type, media_image_version, media_banner_version, logo_image_version, background_image_version, email_image_version, key_take_away_title, key_take_away_timeout, season_number, slide_count, topline_count, season_count, exclude_in_new_releases, send_newsletter, id, created_at, updated_at) {
    Media.initialize(this, media_type, title, allow_comments, font_type, media_image_version, media_banner_version, logo_image_version, background_image_version, email_image_version, key_take_away_title, key_take_away_timeout, season_number, slide_count, topline_count, season_count, exclude_in_new_releases, send_newsletter, id, created_at, updated_at);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, media_type, title, allow_comments, font_type, media_image_version, media_banner_version, logo_image_version, background_image_version, email_image_version, key_take_away_title, key_take_away_timeout, season_number, slide_count, topline_count, season_count, exclude_in_new_releases, send_newsletter, id, created_at, updated_at) {
    obj['media_type'] = media_type;
    obj['title'] = title;
    obj['allow_comments'] = allow_comments;
    obj['font_type'] = font_type;
    obj['media_image_version'] = media_image_version;
    obj['media_banner_version'] = media_banner_version;
    obj['logo_image_version'] = logo_image_version;
    obj['background_image_version'] = background_image_version;
    obj['email_image_version'] = email_image_version;
    obj['key_take_away_title'] = key_take_away_title;
    obj['key_take_away_timeout'] = key_take_away_timeout;
    obj['season_number'] = season_number;
    obj['slide_count'] = slide_count;
    obj['topline_count'] = topline_count;
    obj['season_count'] = season_count;
    obj['exclude_in_new_releases'] = exclude_in_new_releases;
    obj['send_newsletter'] = send_newsletter;
    obj['id'] = id;
    obj['created_at'] = created_at;
    obj['updated_at'] = updated_at;
  }

  /**
   * Constructs a <code>Media</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Media} obj Optional instance to populate.
   * @return {module:model/Media} The populated <code>Media</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Media();
      if (data.hasOwnProperty('customer_owner_id')) {
        obj['customer_owner_id'] = _ApiClient.default.convertToType(data['customer_owner_id'], 'String');
      }
      if (data.hasOwnProperty('media_type')) {
        obj['media_type'] = _DefinedMediaType.default.constructFromObject(data['media_type']);
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = _ApiClient.default.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('price')) {
        obj['price'] = _ApiClient.default.convertToType(data['price'], 'Number');
      }
      if (data.hasOwnProperty('allow_comments')) {
        obj['allow_comments'] = _ApiClient.default.convertToType(data['allow_comments'], 'Boolean');
      }
      if (data.hasOwnProperty('media_image_id')) {
        obj['media_image_id'] = _ApiClient.default.convertToType(data['media_image_id'], 'String');
      }
      if (data.hasOwnProperty('media_banner_id')) {
        obj['media_banner_id'] = _ApiClient.default.convertToType(data['media_banner_id'], 'String');
      }
      if (data.hasOwnProperty('logo_image_id')) {
        obj['logo_image_id'] = _ApiClient.default.convertToType(data['logo_image_id'], 'String');
      }
      if (data.hasOwnProperty('background_image_id')) {
        obj['background_image_id'] = _ApiClient.default.convertToType(data['background_image_id'], 'String');
      }
      if (data.hasOwnProperty('media_file_id')) {
        obj['media_file_id'] = _ApiClient.default.convertToType(data['media_file_id'], 'String');
      }
      if (data.hasOwnProperty('email_image_id')) {
        obj['email_image_id'] = _ApiClient.default.convertToType(data['email_image_id'], 'String');
      }
      if (data.hasOwnProperty('distribution_plan_id')) {
        obj['distribution_plan_id'] = _ApiClient.default.convertToType(data['distribution_plan_id'], 'String');
      }
      if (data.hasOwnProperty('country_id')) {
        obj['country_id'] = _ApiClient.default.convertToType(data['country_id'], 'String');
      }
      if (data.hasOwnProperty('available_from')) {
        obj['available_from'] = _ApiClient.default.convertToType(data['available_from'], 'String');
      }
      if (data.hasOwnProperty('font_type')) {
        obj['font_type'] = _FontTypes.default.constructFromObject(data['font_type']);
      }
      if (data.hasOwnProperty('media_image_version')) {
        obj['media_image_version'] = _ApiClient.default.convertToType(data['media_image_version'], 'Number');
      }
      if (data.hasOwnProperty('media_banner_version')) {
        obj['media_banner_version'] = _ApiClient.default.convertToType(data['media_banner_version'], 'Number');
      }
      if (data.hasOwnProperty('logo_image_version')) {
        obj['logo_image_version'] = _ApiClient.default.convertToType(data['logo_image_version'], 'Number');
      }
      if (data.hasOwnProperty('background_image_version')) {
        obj['background_image_version'] = _ApiClient.default.convertToType(data['background_image_version'], 'Number');
      }
      if (data.hasOwnProperty('email_image_version')) {
        obj['email_image_version'] = _ApiClient.default.convertToType(data['email_image_version'], 'Number');
      }
      if (data.hasOwnProperty('source')) {
        obj['source'] = _ApiClient.default.convertToType(data['source'], 'String');
      }
      if (data.hasOwnProperty('webinar_link')) {
        obj['webinar_link'] = _ApiClient.default.convertToType(data['webinar_link'], 'String');
      }
      if (data.hasOwnProperty('webinar_link_en')) {
        obj['webinar_link_en'] = _ApiClient.default.convertToType(data['webinar_link_en'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('short_description')) {
        obj['short_description'] = _ApiClient.default.convertToType(data['short_description'], 'String');
      }
      if (data.hasOwnProperty('key_take_away_title')) {
        obj['key_take_away_title'] = _ApiClient.default.convertToType(data['key_take_away_title'], 'String');
      }
      if (data.hasOwnProperty('key_take_away_timeout')) {
        obj['key_take_away_timeout'] = _ApiClient.default.convertToType(data['key_take_away_timeout'], 'Number');
      }
      if (data.hasOwnProperty('season_parent_id')) {
        obj['season_parent_id'] = _ApiClient.default.convertToType(data['season_parent_id'], 'String');
      }
      if (data.hasOwnProperty('season_number')) {
        obj['season_number'] = _ApiClient.default.convertToType(data['season_number'], 'Number');
      }
      if (data.hasOwnProperty('season_caption')) {
        obj['season_caption'] = _ApiClient.default.convertToType(data['season_caption'], 'String');
      }
      if (data.hasOwnProperty('slide_count')) {
        obj['slide_count'] = _ApiClient.default.convertToType(data['slide_count'], 'Number');
      }
      if (data.hasOwnProperty('topline_count')) {
        obj['topline_count'] = _ApiClient.default.convertToType(data['topline_count'], 'Number');
      }
      if (data.hasOwnProperty('season_count')) {
        obj['season_count'] = _ApiClient.default.convertToType(data['season_count'], 'Number');
      }
      if (data.hasOwnProperty('exclude_in_new_releases')) {
        obj['exclude_in_new_releases'] = _ApiClient.default.convertToType(data['exclude_in_new_releases'], 'Boolean');
      }
      if (data.hasOwnProperty('send_newsletter')) {
        obj['send_newsletter'] = _ApiClient.default.convertToType(data['send_newsletter'], 'Boolean');
      }
      if (data.hasOwnProperty('meeting_url')) {
        obj['meeting_url'] = _ApiClient.default.convertToType(data['meeting_url'], 'String');
      }
      if (data.hasOwnProperty('meeting_date')) {
        obj['meeting_date'] = _ApiClient.default.convertToType(data['meeting_date'], 'String');
      }
      if (data.hasOwnProperty('meeting_time')) {
        obj['meeting_time'] = _ApiClient.default.convertToType(data['meeting_time'], 'String');
      }
      if (data.hasOwnProperty('email_opens')) {
        obj['email_opens'] = _ApiClient.default.convertToType(data['email_opens'], 'Number');
      }
      if (data.hasOwnProperty('email_clicks')) {
        obj['email_clicks'] = _ApiClient.default.convertToType(data['email_clicks'], 'Number');
      }
      if (data.hasOwnProperty('favorited')) {
        obj['favorited'] = _ApiClient.default.convertToType(data['favorited'], 'Number');
      }
      if (data.hasOwnProperty('liked')) {
        obj['liked'] = _ApiClient.default.convertToType(data['liked'], 'Number');
      }
      if (data.hasOwnProperty('user_read')) {
        obj['user_read'] = _ApiClient.default.convertToType(data['user_read'], 'Number');
      }
      if (data.hasOwnProperty('customer_read')) {
        obj['customer_read'] = _ApiClient.default.convertToType(data['customer_read'], 'Number');
      }
      if (data.hasOwnProperty('in_listed')) {
        obj['in_listed'] = _ApiClient.default.convertToType(data['in_listed'], 'Number');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('created_at')) {
        obj['created_at'] = _ApiClient.default.convertToType(data['created_at'], 'String');
      }
      if (data.hasOwnProperty('created_by')) {
        obj['created_by'] = _ApiClient.default.convertToType(data['created_by'], 'String');
      }
      if (data.hasOwnProperty('date_created')) {
        obj['date_created'] = _ApiClient.default.convertToType(data['date_created'], 'String');
      }
      if (data.hasOwnProperty('time_created')) {
        obj['time_created'] = _ApiClient.default.convertToType(data['time_created'], 'String');
      }
      if (data.hasOwnProperty('updated_at')) {
        obj['updated_at'] = _ApiClient.default.convertToType(data['updated_at'], 'String');
      }
      if (data.hasOwnProperty('updated_by')) {
        obj['updated_by'] = _ApiClient.default.convertToType(data['updated_by'], 'String');
      }
      if (data.hasOwnProperty('date_updated')) {
        obj['date_updated'] = _ApiClient.default.convertToType(data['date_updated'], 'String');
      }
      if (data.hasOwnProperty('time_updated')) {
        obj['time_updated'] = _ApiClient.default.convertToType(data['time_updated'], 'String');
      }
      if (data.hasOwnProperty('deleted_at')) {
        obj['deleted_at'] = _ApiClient.default.convertToType(data['deleted_at'], 'String');
      }
      if (data.hasOwnProperty('deleted_by')) {
        obj['deleted_by'] = _ApiClient.default.convertToType(data['deleted_by'], 'String');
      }
      if (data.hasOwnProperty('date_deleted')) {
        obj['date_deleted'] = _ApiClient.default.convertToType(data['date_deleted'], 'String');
      }
      if (data.hasOwnProperty('time_deleted')) {
        obj['time_deleted'] = _ApiClient.default.convertToType(data['time_deleted'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>Media</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Media</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of Media.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['customer_owner_id'] && !(typeof data['customer_owner_id'] === 'string' || data['customer_owner_id'] instanceof String)) {
      throw new Error("Expected the field `customer_owner_id` to be a primitive type in the JSON string but got " + data['customer_owner_id']);
    }
    // ensure the json data is a string
    if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
      throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
    }
    // ensure the json data is a string
    if (data['media_image_id'] && !(typeof data['media_image_id'] === 'string' || data['media_image_id'] instanceof String)) {
      throw new Error("Expected the field `media_image_id` to be a primitive type in the JSON string but got " + data['media_image_id']);
    }
    // ensure the json data is a string
    if (data['media_banner_id'] && !(typeof data['media_banner_id'] === 'string' || data['media_banner_id'] instanceof String)) {
      throw new Error("Expected the field `media_banner_id` to be a primitive type in the JSON string but got " + data['media_banner_id']);
    }
    // ensure the json data is a string
    if (data['logo_image_id'] && !(typeof data['logo_image_id'] === 'string' || data['logo_image_id'] instanceof String)) {
      throw new Error("Expected the field `logo_image_id` to be a primitive type in the JSON string but got " + data['logo_image_id']);
    }
    // ensure the json data is a string
    if (data['background_image_id'] && !(typeof data['background_image_id'] === 'string' || data['background_image_id'] instanceof String)) {
      throw new Error("Expected the field `background_image_id` to be a primitive type in the JSON string but got " + data['background_image_id']);
    }
    // ensure the json data is a string
    if (data['media_file_id'] && !(typeof data['media_file_id'] === 'string' || data['media_file_id'] instanceof String)) {
      throw new Error("Expected the field `media_file_id` to be a primitive type in the JSON string but got " + data['media_file_id']);
    }
    // ensure the json data is a string
    if (data['email_image_id'] && !(typeof data['email_image_id'] === 'string' || data['email_image_id'] instanceof String)) {
      throw new Error("Expected the field `email_image_id` to be a primitive type in the JSON string but got " + data['email_image_id']);
    }
    // ensure the json data is a string
    if (data['distribution_plan_id'] && !(typeof data['distribution_plan_id'] === 'string' || data['distribution_plan_id'] instanceof String)) {
      throw new Error("Expected the field `distribution_plan_id` to be a primitive type in the JSON string but got " + data['distribution_plan_id']);
    }
    // ensure the json data is a string
    if (data['country_id'] && !(typeof data['country_id'] === 'string' || data['country_id'] instanceof String)) {
      throw new Error("Expected the field `country_id` to be a primitive type in the JSON string but got " + data['country_id']);
    }
    // ensure the json data is a string
    if (data['source'] && !(typeof data['source'] === 'string' || data['source'] instanceof String)) {
      throw new Error("Expected the field `source` to be a primitive type in the JSON string but got " + data['source']);
    }
    // ensure the json data is a string
    if (data['webinar_link'] && !(typeof data['webinar_link'] === 'string' || data['webinar_link'] instanceof String)) {
      throw new Error("Expected the field `webinar_link` to be a primitive type in the JSON string but got " + data['webinar_link']);
    }
    // ensure the json data is a string
    if (data['webinar_link_en'] && !(typeof data['webinar_link_en'] === 'string' || data['webinar_link_en'] instanceof String)) {
      throw new Error("Expected the field `webinar_link_en` to be a primitive type in the JSON string but got " + data['webinar_link_en']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is a string
    if (data['short_description'] && !(typeof data['short_description'] === 'string' || data['short_description'] instanceof String)) {
      throw new Error("Expected the field `short_description` to be a primitive type in the JSON string but got " + data['short_description']);
    }
    // ensure the json data is a string
    if (data['key_take_away_title'] && !(typeof data['key_take_away_title'] === 'string' || data['key_take_away_title'] instanceof String)) {
      throw new Error("Expected the field `key_take_away_title` to be a primitive type in the JSON string but got " + data['key_take_away_title']);
    }
    // ensure the json data is a string
    if (data['season_parent_id'] && !(typeof data['season_parent_id'] === 'string' || data['season_parent_id'] instanceof String)) {
      throw new Error("Expected the field `season_parent_id` to be a primitive type in the JSON string but got " + data['season_parent_id']);
    }
    // ensure the json data is a string
    if (data['season_caption'] && !(typeof data['season_caption'] === 'string' || data['season_caption'] instanceof String)) {
      throw new Error("Expected the field `season_caption` to be a primitive type in the JSON string but got " + data['season_caption']);
    }
    // ensure the json data is a string
    if (data['meeting_url'] && !(typeof data['meeting_url'] === 'string' || data['meeting_url'] instanceof String)) {
      throw new Error("Expected the field `meeting_url` to be a primitive type in the JSON string but got " + data['meeting_url']);
    }
    // ensure the json data is a string
    if (data['meeting_time'] && !(typeof data['meeting_time'] === 'string' || data['meeting_time'] instanceof String)) {
      throw new Error("Expected the field `meeting_time` to be a primitive type in the JSON string but got " + data['meeting_time']);
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
      throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
    }
    // ensure the json data is a string
    if (data['time_created'] && !(typeof data['time_created'] === 'string' || data['time_created'] instanceof String)) {
      throw new Error("Expected the field `time_created` to be a primitive type in the JSON string but got " + data['time_created']);
    }
    // ensure the json data is a string
    if (data['updated_by'] && !(typeof data['updated_by'] === 'string' || data['updated_by'] instanceof String)) {
      throw new Error("Expected the field `updated_by` to be a primitive type in the JSON string but got " + data['updated_by']);
    }
    // ensure the json data is a string
    if (data['time_updated'] && !(typeof data['time_updated'] === 'string' || data['time_updated'] instanceof String)) {
      throw new Error("Expected the field `time_updated` to be a primitive type in the JSON string but got " + data['time_updated']);
    }
    // ensure the json data is a string
    if (data['deleted_by'] && !(typeof data['deleted_by'] === 'string' || data['deleted_by'] instanceof String)) {
      throw new Error("Expected the field `deleted_by` to be a primitive type in the JSON string but got " + data['deleted_by']);
    }
    // ensure the json data is a string
    if (data['time_deleted'] && !(typeof data['time_deleted'] === 'string' || data['time_deleted'] instanceof String)) {
      throw new Error("Expected the field `time_deleted` to be a primitive type in the JSON string but got " + data['time_deleted']);
    }
    return true;
  }
}
Media.RequiredProperties = ["media_type", "title", "allow_comments", "font_type", "media_image_version", "media_banner_version", "logo_image_version", "background_image_version", "email_image_version", "key_take_away_title", "key_take_away_timeout", "season_number", "slide_count", "topline_count", "season_count", "exclude_in_new_releases", "send_newsletter", "id", "created_at", "updated_at"];

/**
 * @member {String} customer_owner_id
 */
Media.prototype['customer_owner_id'] = undefined;

/**
 * @member {module:model/DefinedMediaType} media_type
 */
Media.prototype['media_type'] = undefined;

/**
 * @member {String} title
 */
Media.prototype['title'] = undefined;

/**
 * @member {Number} price
 */
Media.prototype['price'] = undefined;

/**
 * @member {Boolean} allow_comments
 */
Media.prototype['allow_comments'] = undefined;

/**
 * @member {String} media_image_id
 */
Media.prototype['media_image_id'] = undefined;

/**
 * @member {String} media_banner_id
 */
Media.prototype['media_banner_id'] = undefined;

/**
 * @member {String} logo_image_id
 */
Media.prototype['logo_image_id'] = undefined;

/**
 * @member {String} background_image_id
 */
Media.prototype['background_image_id'] = undefined;

/**
 * @member {String} media_file_id
 */
Media.prototype['media_file_id'] = undefined;

/**
 * @member {String} email_image_id
 */
Media.prototype['email_image_id'] = undefined;

/**
 * @member {String} distribution_plan_id
 */
Media.prototype['distribution_plan_id'] = undefined;

/**
 * @member {String} country_id
 */
Media.prototype['country_id'] = undefined;

/**
 * @member {String} available_from
 */
Media.prototype['available_from'] = undefined;

/**
 * @member {module:model/FontTypes} font_type
 */
Media.prototype['font_type'] = undefined;

/**
 * @member {Number} media_image_version
 */
Media.prototype['media_image_version'] = undefined;

/**
 * @member {Number} media_banner_version
 */
Media.prototype['media_banner_version'] = undefined;

/**
 * @member {Number} logo_image_version
 */
Media.prototype['logo_image_version'] = undefined;

/**
 * @member {Number} background_image_version
 */
Media.prototype['background_image_version'] = undefined;

/**
 * @member {Number} email_image_version
 */
Media.prototype['email_image_version'] = undefined;

/**
 * @member {String} source
 */
Media.prototype['source'] = undefined;

/**
 * @member {String} webinar_link
 */
Media.prototype['webinar_link'] = undefined;

/**
 * @member {String} webinar_link_en
 */
Media.prototype['webinar_link_en'] = undefined;

/**
 * @member {String} description
 */
Media.prototype['description'] = undefined;

/**
 * @member {String} short_description
 */
Media.prototype['short_description'] = undefined;

/**
 * @member {String} key_take_away_title
 */
Media.prototype['key_take_away_title'] = undefined;

/**
 * @member {Number} key_take_away_timeout
 */
Media.prototype['key_take_away_timeout'] = undefined;

/**
 * @member {String} season_parent_id
 */
Media.prototype['season_parent_id'] = undefined;

/**
 * @member {Number} season_number
 */
Media.prototype['season_number'] = undefined;

/**
 * @member {String} season_caption
 */
Media.prototype['season_caption'] = undefined;

/**
 * @member {Number} slide_count
 */
Media.prototype['slide_count'] = undefined;

/**
 * @member {Number} topline_count
 */
Media.prototype['topline_count'] = undefined;

/**
 * @member {Number} season_count
 */
Media.prototype['season_count'] = undefined;

/**
 * @member {Boolean} exclude_in_new_releases
 */
Media.prototype['exclude_in_new_releases'] = undefined;

/**
 * @member {Boolean} send_newsletter
 */
Media.prototype['send_newsletter'] = undefined;

/**
 * @member {String} meeting_url
 */
Media.prototype['meeting_url'] = undefined;

/**
 * @member {String} meeting_date
 */
Media.prototype['meeting_date'] = undefined;

/**
 * @member {String} meeting_time
 */
Media.prototype['meeting_time'] = undefined;

/**
 * @member {Number} email_opens
 */
Media.prototype['email_opens'] = undefined;

/**
 * @member {Number} email_clicks
 */
Media.prototype['email_clicks'] = undefined;

/**
 * @member {Number} favorited
 */
Media.prototype['favorited'] = undefined;

/**
 * @member {Number} liked
 */
Media.prototype['liked'] = undefined;

/**
 * @member {Number} user_read
 */
Media.prototype['user_read'] = undefined;

/**
 * @member {Number} customer_read
 */
Media.prototype['customer_read'] = undefined;

/**
 * @member {Number} in_listed
 */
Media.prototype['in_listed'] = undefined;

/**
 * @member {String} id
 */
Media.prototype['id'] = undefined;

/**
 * @member {String} created_at
 */
Media.prototype['created_at'] = undefined;

/**
 * @member {String} created_by
 */
Media.prototype['created_by'] = undefined;

/**
 * @member {String} date_created
 */
Media.prototype['date_created'] = undefined;

/**
 * @member {String} time_created
 */
Media.prototype['time_created'] = undefined;

/**
 * @member {String} updated_at
 */
Media.prototype['updated_at'] = undefined;

/**
 * @member {String} updated_by
 */
Media.prototype['updated_by'] = undefined;

/**
 * @member {String} date_updated
 */
Media.prototype['date_updated'] = undefined;

/**
 * @member {String} time_updated
 */
Media.prototype['time_updated'] = undefined;

/**
 * @member {String} deleted_at
 */
Media.prototype['deleted_at'] = undefined;

/**
 * @member {String} deleted_by
 */
Media.prototype['deleted_by'] = undefined;

/**
 * @member {String} date_deleted
 */
Media.prototype['date_deleted'] = undefined;

/**
 * @member {String} time_deleted
 */
Media.prototype['time_deleted'] = undefined;
var _default = Media;
exports.default = _default;