"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The MailgunGeolocation model module.
 * @module model/MailgunGeolocation
 * @version 1.1.3-alpha.1
 */
class MailgunGeolocation {
  /**
   * Constructs a new <code>MailgunGeolocation</code>.
   * @alias module:model/MailgunGeolocation
   */
  constructor() {
    MailgunGeolocation.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MailgunGeolocation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MailgunGeolocation} obj Optional instance to populate.
   * @return {module:model/MailgunGeolocation} The populated <code>MailgunGeolocation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MailgunGeolocation();
      if (data.hasOwnProperty('country')) {
        obj['country'] = _ApiClient.default.convertToType(data['country'], 'String');
      }
      if (data.hasOwnProperty('region')) {
        obj['region'] = _ApiClient.default.convertToType(data['region'], 'String');
      }
      if (data.hasOwnProperty('city')) {
        obj['city'] = _ApiClient.default.convertToType(data['city'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>MailgunGeolocation</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MailgunGeolocation</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['country'] && !(typeof data['country'] === 'string' || data['country'] instanceof String)) {
      throw new Error("Expected the field `country` to be a primitive type in the JSON string but got " + data['country']);
    }
    // ensure the json data is a string
    if (data['region'] && !(typeof data['region'] === 'string' || data['region'] instanceof String)) {
      throw new Error("Expected the field `region` to be a primitive type in the JSON string but got " + data['region']);
    }
    // ensure the json data is a string
    if (data['city'] && !(typeof data['city'] === 'string' || data['city'] instanceof String)) {
      throw new Error("Expected the field `city` to be a primitive type in the JSON string but got " + data['city']);
    }
    return true;
  }
}

/**
 * @member {String} country
 */
MailgunGeolocation.prototype['country'] = undefined;

/**
 * @member {String} region
 */
MailgunGeolocation.prototype['region'] = undefined;

/**
 * @member {String} city
 */
MailgunGeolocation.prototype['city'] = undefined;
var _default = MailgunGeolocation;
exports.default = _default;