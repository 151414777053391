"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CommandAddTag model module.
 * @module model/CommandAddTag
 * @version 1.1.3-alpha.1
 */
class CommandAddTag {
  /**
   * Constructs a new <code>CommandAddTag</code>.
   * @alias module:model/CommandAddTag
   * @param name {String} 
   */
  constructor(name) {
    CommandAddTag.initialize(this, name);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, name) {
    obj['name'] = name;
  }

  /**
   * Constructs a <code>CommandAddTag</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CommandAddTag} obj Optional instance to populate.
   * @return {module:model/CommandAddTag} The populated <code>CommandAddTag</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CommandAddTag();
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CommandAddTag</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CommandAddTag</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CommandAddTag.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    return true;
  }
}
CommandAddTag.RequiredProperties = ["name"];

/**
 * @member {String} name
 */
CommandAddTag.prototype['name'] = undefined;
var _default = CommandAddTag;
exports.default = _default;