"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Customer = _interopRequireDefault(require("./Customer"));
var _CustomerContact = _interopRequireDefault(require("./CustomerContact"));
var _Role = _interopRequireDefault(require("./Role"));
var _Subscription = _interopRequireDefault(require("./Subscription"));
var _User = _interopRequireDefault(require("./User"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The LoginInfo model module.
 * @module model/LoginInfo
 * @version 1.1.3-alpha.1
 */
class LoginInfo {
  /**
   * Constructs a new <code>LoginInfo</code>.
   * @alias module:model/LoginInfo
   */
  constructor() {
    LoginInfo.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>LoginInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LoginInfo} obj Optional instance to populate.
   * @return {module:model/LoginInfo} The populated <code>LoginInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LoginInfo();
      if (data.hasOwnProperty('user')) {
        obj['user'] = _User.default.constructFromObject(data['user']);
      }
      if (data.hasOwnProperty('role')) {
        obj['role'] = _Role.default.constructFromObject(data['role']);
      }
      if (data.hasOwnProperty('customer')) {
        obj['customer'] = _Customer.default.constructFromObject(data['customer']);
      }
      if (data.hasOwnProperty('contact')) {
        obj['contact'] = _CustomerContact.default.constructFromObject(data['contact']);
      }
      if (data.hasOwnProperty('subscription')) {
        obj['subscription'] = _Subscription.default.constructFromObject(data['subscription']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>LoginInfo</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>LoginInfo</code>.
   */
  static validateJSON(data) {
    // validate the optional field `user`
    if (data['user']) {
      // data not null
      _User.default.validateJSON(data['user']);
    }
    // validate the optional field `role`
    if (data['role']) {
      // data not null
      _Role.default.validateJSON(data['role']);
    }
    // validate the optional field `customer`
    if (data['customer']) {
      // data not null
      _Customer.default.validateJSON(data['customer']);
    }
    // validate the optional field `contact`
    if (data['contact']) {
      // data not null
      _CustomerContact.default.validateJSON(data['contact']);
    }
    // validate the optional field `subscription`
    if (data['subscription']) {
      // data not null
      _Subscription.default.validateJSON(data['subscription']);
    }
    return true;
  }
}

/**
 * @member {module:model/User} user
 */
LoginInfo.prototype['user'] = undefined;

/**
 * @member {module:model/Role} role
 */
LoginInfo.prototype['role'] = undefined;

/**
 * @member {module:model/Customer} customer
 */
LoginInfo.prototype['customer'] = undefined;

/**
 * @member {module:model/CustomerContact} contact
 */
LoginInfo.prototype['contact'] = undefined;

/**
 * @member {module:model/Subscription} subscription
 */
LoginInfo.prototype['subscription'] = undefined;
var _default = LoginInfo;
exports.default = _default;