"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _CustomerType = _interopRequireDefault(require("./CustomerType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UpdateMyAccountModel model module.
 * @module model/UpdateMyAccountModel
 * @version 1.1.3-alpha.1
 */
class UpdateMyAccountModel {
  /**
   * Constructs a new <code>UpdateMyAccountModel</code>.
   * @alias module:model/UpdateMyAccountModel
   */
  constructor() {
    UpdateMyAccountModel.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>UpdateMyAccountModel</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UpdateMyAccountModel} obj Optional instance to populate.
   * @return {module:model/UpdateMyAccountModel} The populated <code>UpdateMyAccountModel</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UpdateMyAccountModel();
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('lastName')) {
        obj['lastName'] = _ApiClient.default.convertToType(data['lastName'], 'String');
      }
      if (data.hasOwnProperty('mobile')) {
        obj['mobile'] = _ApiClient.default.convertToType(data['mobile'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = _CustomerType.default.constructFromObject(data['type']);
      }
      if (data.hasOwnProperty('afm')) {
        obj['afm'] = _ApiClient.default.convertToType(data['afm'], 'String');
      }
      if (data.hasOwnProperty('doy')) {
        obj['doy'] = _ApiClient.default.convertToType(data['doy'], 'String');
      }
      if (data.hasOwnProperty('jobDescription')) {
        obj['jobDescription'] = _ApiClient.default.convertToType(data['jobDescription'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UpdateMyAccountModel</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UpdateMyAccountModel</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['lastName'] && !(typeof data['lastName'] === 'string' || data['lastName'] instanceof String)) {
      throw new Error("Expected the field `lastName` to be a primitive type in the JSON string but got " + data['lastName']);
    }
    // ensure the json data is a string
    if (data['mobile'] && !(typeof data['mobile'] === 'string' || data['mobile'] instanceof String)) {
      throw new Error("Expected the field `mobile` to be a primitive type in the JSON string but got " + data['mobile']);
    }
    // ensure the json data is a string
    if (data['afm'] && !(typeof data['afm'] === 'string' || data['afm'] instanceof String)) {
      throw new Error("Expected the field `afm` to be a primitive type in the JSON string but got " + data['afm']);
    }
    // ensure the json data is a string
    if (data['doy'] && !(typeof data['doy'] === 'string' || data['doy'] instanceof String)) {
      throw new Error("Expected the field `doy` to be a primitive type in the JSON string but got " + data['doy']);
    }
    // ensure the json data is a string
    if (data['jobDescription'] && !(typeof data['jobDescription'] === 'string' || data['jobDescription'] instanceof String)) {
      throw new Error("Expected the field `jobDescription` to be a primitive type in the JSON string but got " + data['jobDescription']);
    }
    return true;
  }
}

/**
 * @member {String} name
 */
UpdateMyAccountModel.prototype['name'] = undefined;

/**
 * @member {String} lastName
 */
UpdateMyAccountModel.prototype['lastName'] = undefined;

/**
 * @member {String} mobile
 */
UpdateMyAccountModel.prototype['mobile'] = undefined;

/**
 * @member {module:model/CustomerType} type
 */
UpdateMyAccountModel.prototype['type'] = undefined;

/**
 * @member {String} afm
 */
UpdateMyAccountModel.prototype['afm'] = undefined;

/**
 * @member {String} doy
 */
UpdateMyAccountModel.prototype['doy'] = undefined;

/**
 * @member {String} jobDescription
 */
UpdateMyAccountModel.prototype['jobDescription'] = undefined;
var _default = UpdateMyAccountModel;
exports.default = _default;