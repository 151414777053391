"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ViewUserPreference model module.
 * @module model/ViewUserPreference
 * @version 1.1.3-alpha.1
 */
class ViewUserPreference {
  /**
   * Constructs a new <code>ViewUserPreference</code>.
   * @alias module:model/ViewUserPreference
   * @param id {Number} 
   * @param ordering {Number} 
   * @param user_id {String} 
   */
  constructor(id, ordering, user_id) {
    ViewUserPreference.initialize(this, id, ordering, user_id);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, ordering, user_id) {
    obj['id'] = id;
    obj['ordering'] = ordering;
    obj['user_id'] = user_id;
  }

  /**
   * Constructs a <code>ViewUserPreference</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ViewUserPreference} obj Optional instance to populate.
   * @return {module:model/ViewUserPreference} The populated <code>ViewUserPreference</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ViewUserPreference();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('ordering')) {
        obj['ordering'] = _ApiClient.default.convertToType(data['ordering'], 'Number');
      }
      if (data.hasOwnProperty('user_id')) {
        obj['user_id'] = _ApiClient.default.convertToType(data['user_id'], 'String');
      }
      if (data.hasOwnProperty('value_bool')) {
        obj['value_bool'] = _ApiClient.default.convertToType(data['value_bool'], 'Boolean');
      }
      if (data.hasOwnProperty('value_number')) {
        obj['value_number'] = _ApiClient.default.convertToType(data['value_number'], 'Number');
      }
      if (data.hasOwnProperty('value_string')) {
        obj['value_string'] = _ApiClient.default.convertToType(data['value_string'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ViewUserPreference</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ViewUserPreference</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of ViewUserPreference.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['user_id'] && !(typeof data['user_id'] === 'string' || data['user_id'] instanceof String)) {
      throw new Error("Expected the field `user_id` to be a primitive type in the JSON string but got " + data['user_id']);
    }
    // ensure the json data is a string
    if (data['value_string'] && !(typeof data['value_string'] === 'string' || data['value_string'] instanceof String)) {
      throw new Error("Expected the field `value_string` to be a primitive type in the JSON string but got " + data['value_string']);
    }
    return true;
  }
}
ViewUserPreference.RequiredProperties = ["id", "ordering", "user_id"];

/**
 * @member {Number} id
 */
ViewUserPreference.prototype['id'] = undefined;

/**
 * @member {Number} ordering
 */
ViewUserPreference.prototype['ordering'] = undefined;

/**
 * @member {String} user_id
 */
ViewUserPreference.prototype['user_id'] = undefined;

/**
 * @member {Boolean} value_bool
 */
ViewUserPreference.prototype['value_bool'] = undefined;

/**
 * @member {Number} value_number
 */
ViewUserPreference.prototype['value_number'] = undefined;

/**
 * @member {String} value_string
 */
ViewUserPreference.prototype['value_string'] = undefined;
var _default = ViewUserPreference;
exports.default = _default;