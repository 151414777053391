"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } /**
                                                                                                                                                                                                                                                                                                                                                                                           * eXtend Media Paywall Service API
                                                                                                                                                                                                                                                                                                                                                                                           * Media Wall API Specification
                                                                                                                                                                                                                                                                                                                                                                                           *
                                                                                                                                                                                                                                                                                                                                                                                           * The version of the OpenAPI document: v1
                                                                                                                                                                                                                                                                                                                                                                                           * Contact: t.ioannidis@extend.gr
                                                                                                                                                                                                                                                                                                                                                                                           *
                                                                                                                                                                                                                                                                                                                                                                                           * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
                                                                                                                                                                                                                                                                                                                                                                                           * https://openapi-generator.tech
                                                                                                                                                                                                                                                                                                                                                                                           * Do not edit the class manually.
                                                                                                                                                                                                                                                                                                                                                                                           *
                                                                                                                                                                                                                                                                                                                                                                                           */
/**
* Enum class DefinedCustomerType.
* @enum {}
* @readonly
*/
class DefinedCustomerType {
  constructor() {
    /**
     * value: "Retail"
     * @const
     */
    _defineProperty(this, "Retail", "Retail");
    /**
     * value: "Business"
     * @const
     */
    _defineProperty(this, "Business", "Business");
  }
  /**
  * Returns a <code>DefinedCustomerType</code> enum value from a Javascript object name.
  * @param {Object} data The plain JavaScript object containing the name of the enum value.
  * @return {module:model/DefinedCustomerType} The enum <code>DefinedCustomerType</code> value.
  */
  static constructFromObject(object) {
    return object;
  }
}
exports.default = DefinedCustomerType;