"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The HaveQuestionParams model module.
 * @module model/HaveQuestionParams
 * @version 1.1.3-alpha.1
 */
class HaveQuestionParams {
  /**
   * Constructs a new <code>HaveQuestionParams</code>.
   * @alias module:model/HaveQuestionParams
   * @param question {String} 
   */
  constructor(question) {
    HaveQuestionParams.initialize(this, question);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, question) {
    obj['question'] = question;
  }

  /**
   * Constructs a <code>HaveQuestionParams</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/HaveQuestionParams} obj Optional instance to populate.
   * @return {module:model/HaveQuestionParams} The populated <code>HaveQuestionParams</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new HaveQuestionParams();
      if (data.hasOwnProperty('question')) {
        obj['question'] = _ApiClient.default.convertToType(data['question'], 'String');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>HaveQuestionParams</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>HaveQuestionParams</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of HaveQuestionParams.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['question'] && !(typeof data['question'] === 'string' || data['question'] instanceof String)) {
      throw new Error("Expected the field `question` to be a primitive type in the JSON string but got " + data['question']);
    }
    return true;
  }
}
HaveQuestionParams.RequiredProperties = ["question"];

/**
 * @member {String} question
 */
HaveQuestionParams.prototype['question'] = undefined;
var _default = HaveQuestionParams;
exports.default = _default;