"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The LastSeasonInfo model module.
 * @module model/LastSeasonInfo
 * @version 1.1.3-alpha.1
 */
class LastSeasonInfo {
  /**
   * Constructs a new <code>LastSeasonInfo</code>.
   * @alias module:model/LastSeasonInfo
   */
  constructor() {
    LastSeasonInfo.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>LastSeasonInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LastSeasonInfo} obj Optional instance to populate.
   * @return {module:model/LastSeasonInfo} The populated <code>LastSeasonInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LastSeasonInfo();
      if (data.hasOwnProperty('season_number')) {
        obj['season_number'] = _ApiClient.default.convertToType(data['season_number'], 'Number');
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = _ApiClient.default.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('short_description')) {
        obj['short_description'] = _ApiClient.default.convertToType(data['short_description'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('distribution_plan_id')) {
        obj['distribution_plan_id'] = _ApiClient.default.convertToType(data['distribution_plan_id'], 'String');
      }
      if (data.hasOwnProperty('country_id')) {
        obj['country_id'] = _ApiClient.default.convertToType(data['country_id'], 'String');
      }
      if (data.hasOwnProperty('source')) {
        obj['source'] = _ApiClient.default.convertToType(data['source'], 'String');
      }
      if (data.hasOwnProperty('industry_ids')) {
        obj['industry_ids'] = _ApiClient.default.convertToType(data['industry_ids'], ['String']);
      }
      if (data.hasOwnProperty('category_ids')) {
        obj['category_ids'] = _ApiClient.default.convertToType(data['category_ids'], ['String']);
      }
      if (data.hasOwnProperty('subscription_type_ids')) {
        obj['subscription_type_ids'] = _ApiClient.default.convertToType(data['subscription_type_ids'], ['String']);
      }
      if (data.hasOwnProperty('tag_ids')) {
        obj['tag_ids'] = _ApiClient.default.convertToType(data['tag_ids'], ['Number']);
      }
      if (data.hasOwnProperty('available_in_country_ids')) {
        obj['available_in_country_ids'] = _ApiClient.default.convertToType(data['available_in_country_ids'], ['String']);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>LastSeasonInfo</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>LastSeasonInfo</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
      throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
    }
    // ensure the json data is a string
    if (data['short_description'] && !(typeof data['short_description'] === 'string' || data['short_description'] instanceof String)) {
      throw new Error("Expected the field `short_description` to be a primitive type in the JSON string but got " + data['short_description']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    // ensure the json data is a string
    if (data['distribution_plan_id'] && !(typeof data['distribution_plan_id'] === 'string' || data['distribution_plan_id'] instanceof String)) {
      throw new Error("Expected the field `distribution_plan_id` to be a primitive type in the JSON string but got " + data['distribution_plan_id']);
    }
    // ensure the json data is a string
    if (data['country_id'] && !(typeof data['country_id'] === 'string' || data['country_id'] instanceof String)) {
      throw new Error("Expected the field `country_id` to be a primitive type in the JSON string but got " + data['country_id']);
    }
    // ensure the json data is a string
    if (data['source'] && !(typeof data['source'] === 'string' || data['source'] instanceof String)) {
      throw new Error("Expected the field `source` to be a primitive type in the JSON string but got " + data['source']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['industry_ids'])) {
      throw new Error("Expected the field `industry_ids` to be an array in the JSON data but got " + data['industry_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['category_ids'])) {
      throw new Error("Expected the field `category_ids` to be an array in the JSON data but got " + data['category_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['subscription_type_ids'])) {
      throw new Error("Expected the field `subscription_type_ids` to be an array in the JSON data but got " + data['subscription_type_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['tag_ids'])) {
      throw new Error("Expected the field `tag_ids` to be an array in the JSON data but got " + data['tag_ids']);
    }
    // ensure the json data is an array
    if (!Array.isArray(data['available_in_country_ids'])) {
      throw new Error("Expected the field `available_in_country_ids` to be an array in the JSON data but got " + data['available_in_country_ids']);
    }
    return true;
  }
}

/**
 * @member {Number} season_number
 */
LastSeasonInfo.prototype['season_number'] = undefined;

/**
 * @member {String} title
 */
LastSeasonInfo.prototype['title'] = undefined;

/**
 * @member {String} short_description
 */
LastSeasonInfo.prototype['short_description'] = undefined;

/**
 * @member {String} description
 */
LastSeasonInfo.prototype['description'] = undefined;

/**
 * @member {String} distribution_plan_id
 */
LastSeasonInfo.prototype['distribution_plan_id'] = undefined;

/**
 * @member {String} country_id
 */
LastSeasonInfo.prototype['country_id'] = undefined;

/**
 * @member {String} source
 */
LastSeasonInfo.prototype['source'] = undefined;

/**
 * @member {Array.<String>} industry_ids
 */
LastSeasonInfo.prototype['industry_ids'] = undefined;

/**
 * @member {Array.<String>} category_ids
 */
LastSeasonInfo.prototype['category_ids'] = undefined;

/**
 * @member {Array.<String>} subscription_type_ids
 */
LastSeasonInfo.prototype['subscription_type_ids'] = undefined;

/**
 * @member {Array.<Number>} tag_ids
 */
LastSeasonInfo.prototype['tag_ids'] = undefined;

/**
 * @member {Array.<String>} available_in_country_ids
 */
LastSeasonInfo.prototype['available_in_country_ids'] = undefined;
var _default = LastSeasonInfo;
exports.default = _default;