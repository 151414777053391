"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The AddExpressionOfInterestCommand model module.
 * @module model/AddExpressionOfInterestCommand
 * @version 1.1.3-alpha.1
 */
class AddExpressionOfInterestCommand {
  /**
   * Constructs a new <code>AddExpressionOfInterestCommand</code>.
   * @alias module:model/AddExpressionOfInterestCommand
   * @param email {String} 
   */
  constructor(email) {
    AddExpressionOfInterestCommand.initialize(this, email);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, email) {
    obj['email'] = email;
  }

  /**
   * Constructs a <code>AddExpressionOfInterestCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AddExpressionOfInterestCommand} obj Optional instance to populate.
   * @return {module:model/AddExpressionOfInterestCommand} The populated <code>AddExpressionOfInterestCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AddExpressionOfInterestCommand();
      if (data.hasOwnProperty('name')) {
        obj['name'] = _ApiClient.default.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('company')) {
        obj['company'] = _ApiClient.default.convertToType(data['company'], 'String');
      }
      if (data.hasOwnProperty('country')) {
        obj['country'] = _ApiClient.default.convertToType(data['country'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = _ApiClient.default.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = _ApiClient.default.convertToType(data['message'], 'String');
      }
      if (data.hasOwnProperty('is_ad_test_request')) {
        obj['is_ad_test_request'] = _ApiClient.default.convertToType(data['is_ad_test_request'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>AddExpressionOfInterestCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AddExpressionOfInterestCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of AddExpressionOfInterestCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
      throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
    }
    // ensure the json data is a string
    if (data['company'] && !(typeof data['company'] === 'string' || data['company'] instanceof String)) {
      throw new Error("Expected the field `company` to be a primitive type in the JSON string but got " + data['company']);
    }
    // ensure the json data is a string
    if (data['country'] && !(typeof data['country'] === 'string' || data['country'] instanceof String)) {
      throw new Error("Expected the field `country` to be a primitive type in the JSON string but got " + data['country']);
    }
    // ensure the json data is a string
    if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
      throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
    }
    // ensure the json data is a string
    if (data['message'] && !(typeof data['message'] === 'string' || data['message'] instanceof String)) {
      throw new Error("Expected the field `message` to be a primitive type in the JSON string but got " + data['message']);
    }
    return true;
  }
}
AddExpressionOfInterestCommand.RequiredProperties = ["email"];

/**
 * @member {String} name
 */
AddExpressionOfInterestCommand.prototype['name'] = undefined;

/**
 * @member {String} company
 */
AddExpressionOfInterestCommand.prototype['company'] = undefined;

/**
 * @member {String} country
 */
AddExpressionOfInterestCommand.prototype['country'] = undefined;

/**
 * @member {String} email
 */
AddExpressionOfInterestCommand.prototype['email'] = undefined;

/**
 * @member {String} message
 */
AddExpressionOfInterestCommand.prototype['message'] = undefined;

/**
 * @member {Boolean} is_ad_test_request
 */
AddExpressionOfInterestCommand.prototype['is_ad_test_request'] = undefined;
var _default = AddExpressionOfInterestCommand;
exports.default = _default;