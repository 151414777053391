"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The ReportViewStatistics model module.
 * @module model/ReportViewStatistics
 * @version 1.1.3-alpha.1
 */
class ReportViewStatistics {
  /**
   * Constructs a new <code>ReportViewStatistics</code>.
   * @alias module:model/ReportViewStatistics
   */
  constructor() {
    ReportViewStatistics.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>ReportViewStatistics</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ReportViewStatistics} obj Optional instance to populate.
   * @return {module:model/ReportViewStatistics} The populated <code>ReportViewStatistics</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ReportViewStatistics();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('views')) {
        obj['views'] = _ApiClient.default.convertToType(data['views'], 'Number');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>ReportViewStatistics</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ReportViewStatistics</code>.
   */
  static validateJSON(data) {
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    return true;
  }
}

/**
 * @member {String} id
 */
ReportViewStatistics.prototype['id'] = undefined;

/**
 * @member {Number} views
 */
ReportViewStatistics.prototype['views'] = undefined;
var _default = ReportViewStatistics;
exports.default = _default;