"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * eXtend Media Paywall Service API
 * Media Wall API Specification
 *
 * The version of the OpenAPI document: v1
 * Contact: t.ioannidis@extend.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The UpdateSubscriptionTypeCommand model module.
 * @module model/UpdateSubscriptionTypeCommand
 * @version 1.1.3-alpha.1
 */
class UpdateSubscriptionTypeCommand {
  /**
   * Constructs a new <code>UpdateSubscriptionTypeCommand</code>.
   * @alias module:model/UpdateSubscriptionTypeCommand
   * @param id {String} 
   * @param title {String} 
   * @param sorting_order {Number} 
   * @param duration {Number} 
   * @param max_file_size {Number} 
   * @param max_total_size {Number} 
   * @param max_uploads {Number} 
   * @param user_limit {Number} 
   * @param zoom_meeting_enabled {Boolean} 
   */
  constructor(id, title, sorting_order, duration, max_file_size, max_total_size, max_uploads, user_limit, zoom_meeting_enabled) {
    UpdateSubscriptionTypeCommand.initialize(this, id, title, sorting_order, duration, max_file_size, max_total_size, max_uploads, user_limit, zoom_meeting_enabled);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, id, title, sorting_order, duration, max_file_size, max_total_size, max_uploads, user_limit, zoom_meeting_enabled) {
    obj['id'] = id;
    obj['title'] = title;
    obj['sorting_order'] = sorting_order;
    obj['duration'] = duration;
    obj['max_file_size'] = max_file_size;
    obj['max_total_size'] = max_total_size;
    obj['max_uploads'] = max_uploads;
    obj['user_limit'] = user_limit;
    obj['zoom_meeting_enabled'] = zoom_meeting_enabled;
  }

  /**
   * Constructs a <code>UpdateSubscriptionTypeCommand</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UpdateSubscriptionTypeCommand} obj Optional instance to populate.
   * @return {module:model/UpdateSubscriptionTypeCommand} The populated <code>UpdateSubscriptionTypeCommand</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UpdateSubscriptionTypeCommand();
      if (data.hasOwnProperty('id')) {
        obj['id'] = _ApiClient.default.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('is_trial')) {
        obj['is_trial'] = _ApiClient.default.convertToType(data['is_trial'], 'Boolean');
      }
      if (data.hasOwnProperty('adscores_accessible')) {
        obj['adscores_accessible'] = _ApiClient.default.convertToType(data['adscores_accessible'], 'Boolean');
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = _ApiClient.default.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('sorting_order')) {
        obj['sorting_order'] = _ApiClient.default.convertToType(data['sorting_order'], 'Number');
      }
      if (data.hasOwnProperty('duration')) {
        obj['duration'] = _ApiClient.default.convertToType(data['duration'], 'Number');
      }
      if (data.hasOwnProperty('max_file_size')) {
        obj['max_file_size'] = _ApiClient.default.convertToType(data['max_file_size'], 'Number');
      }
      if (data.hasOwnProperty('max_total_size')) {
        obj['max_total_size'] = _ApiClient.default.convertToType(data['max_total_size'], 'Number');
      }
      if (data.hasOwnProperty('max_uploads')) {
        obj['max_uploads'] = _ApiClient.default.convertToType(data['max_uploads'], 'Number');
      }
      if (data.hasOwnProperty('user_limit')) {
        obj['user_limit'] = _ApiClient.default.convertToType(data['user_limit'], 'Number');
      }
      if (data.hasOwnProperty('price_prepaid')) {
        obj['price_prepaid'] = _ApiClient.default.convertToType(data['price_prepaid'], 'Number');
      }
      if (data.hasOwnProperty('price_subscription')) {
        obj['price_subscription'] = _ApiClient.default.convertToType(data['price_subscription'], 'Number');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = _ApiClient.default.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('zoom_meeting_enabled')) {
        obj['zoom_meeting_enabled'] = _ApiClient.default.convertToType(data['zoom_meeting_enabled'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>UpdateSubscriptionTypeCommand</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UpdateSubscriptionTypeCommand</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of UpdateSubscriptionTypeCommand.RequiredProperties) {
      if (!data[property]) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
      throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
    }
    // ensure the json data is a string
    if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
      throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
    }
    // ensure the json data is a string
    if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
      throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
    }
    return true;
  }
}
UpdateSubscriptionTypeCommand.RequiredProperties = ["id", "title", "sorting_order", "duration", "max_file_size", "max_total_size", "max_uploads", "user_limit", "zoom_meeting_enabled"];

/**
 * @member {String} id
 */
UpdateSubscriptionTypeCommand.prototype['id'] = undefined;

/**
 * @member {Boolean} is_trial
 */
UpdateSubscriptionTypeCommand.prototype['is_trial'] = undefined;

/**
 * @member {Boolean} adscores_accessible
 */
UpdateSubscriptionTypeCommand.prototype['adscores_accessible'] = undefined;

/**
 * @member {String} title
 */
UpdateSubscriptionTypeCommand.prototype['title'] = undefined;

/**
 * @member {Number} sorting_order
 */
UpdateSubscriptionTypeCommand.prototype['sorting_order'] = undefined;

/**
 * @member {Number} duration
 */
UpdateSubscriptionTypeCommand.prototype['duration'] = undefined;

/**
 * @member {Number} max_file_size
 */
UpdateSubscriptionTypeCommand.prototype['max_file_size'] = undefined;

/**
 * @member {Number} max_total_size
 */
UpdateSubscriptionTypeCommand.prototype['max_total_size'] = undefined;

/**
 * @member {Number} max_uploads
 */
UpdateSubscriptionTypeCommand.prototype['max_uploads'] = undefined;

/**
 * @member {Number} user_limit
 */
UpdateSubscriptionTypeCommand.prototype['user_limit'] = undefined;

/**
 * @member {Number} price_prepaid
 */
UpdateSubscriptionTypeCommand.prototype['price_prepaid'] = undefined;

/**
 * @member {Number} price_subscription
 */
UpdateSubscriptionTypeCommand.prototype['price_subscription'] = undefined;

/**
 * @member {String} description
 */
UpdateSubscriptionTypeCommand.prototype['description'] = undefined;

/**
 * @member {Boolean} zoom_meeting_enabled
 */
UpdateSubscriptionTypeCommand.prototype['zoom_meeting_enabled'] = undefined;
var _default = UpdateSubscriptionTypeCommand;
exports.default = _default;